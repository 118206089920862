﻿/**
 * default variables can be ovverride
 */
$break-mobile: 1000px !default;

@mixin mobile() {
  @media only screen and (max-width: $break-mobile - 1) {
    @content;
  }
}
@mixin desktop() {
  @media only screen and (min-width: $break-mobile) {
    @content;
  }
}
