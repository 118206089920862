﻿/*  =POPUPS
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.popup {
    width: 100px;   // default value
    height: 60px;  // default value
    background: #fff;
    color: #000;
    position: fixed;
    left:0; right:0; top:0; bottom:0;
    margin: auto;
    z-index: 999998;
    opacity: 0;

    .closePopup {
        width: 10px;
        height: 10px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 999999;
        background: url("../img/ModalDialogClose.png");
    }
    @include mobile {
        .iScrollVerticalScrollbar  {
            display: none;
        }
    }
}



    // http://codepen.io/w3core/pen/raRXOy
    .PopupFluid {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 65535;
        display: table;
        top:0; right:0; bottom:0; left:0;
        background: rgba(0,0,0,.4);
    }
    .PopupFluid > .Popup-holder {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .PopupFluid > .Popup-holder > .Popup-body {
        position: relative;
        display: inline-block;
        background: #fff;
        max-width: 90%;
        max-height: 90%;
        overflow: auto;
        text-align: left;
    }





/*  =COUNTRY SELECTOR POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.popup.countrySelectorPopup {
    background: #1a1a1a;
    width: 510px;
    height: 300px;
    color: #fff;
    .popupContent {
        padding: 35px;
        position: relative;
        .popupTitle {
            margin-bottom: 35px;
            font-size: 1.5rem;
            line-height: normal;
            font-weight: 800;
        }
        .popupList__item {
            margin-bottom: 20px;
            line-height: 16px;
            font-size: 1.4rem;
            width: 220px;
            float: left;

            .popupList__countryImg {
                vertical-align: middle;
                width: 16px;
                height: 12px;
            }
            .popupList__countryName {
                vertical-align: middle;
                padding-left: 15px;
                font-weight: 400;
            }
            a {
                color: #b8b8b8;
            }
        }
    }
}




/*  =SORT POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.popupSortMobile {
    @include desktop {display: none;}
    width: 96%;
    height: 200px;
    padding: 15px;
    background-color: #1a1a1a;
    border: 1px solid #666;
    @include clearfix;

    .sort__title {
        font-size: 1.2rem;
        margin-bottom: 20px;
        color: #fff;
    }

    .sortList {
        @include clearfix;

        .sortList__item {
            width: 49.5%;
            float: left;
            margin-right: 1%;
            margin-bottom: 1%;

            &:nth-child(2n+2) {
                margin-right: 0;
            }
            &.active {
                background-color: #444;
            }

            a {
                display: block;
                font-size: 1.2rem;
                line-height: normal;
                padding: 10px 0;
                padding-left: 10px;
                border: 1px solid #666;
            }
        }
    }
}






/*  =IMAGE POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.productPicturePopup {
    width: 760px;
    height: 720px;
    background: #0d0d0d;

    h2.title {
        font-size: 1.6rem;
        color: #fff;
        margin: 40px 0 20px 40px;
    }
    .imageWrap {
        text-align: center;
        img {
            width: 457px;
            height: 580px;
            margin: 0 auto;
        }
    }
}




/*  =PRODUCT TILE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.productTilePopup {
    width: 720px;
    height: 680px;
    padding: 40px;
    background: #0d0d0d;
    color: #bbb;
    font-size: 1.2rem;

    .variant__elements {
        ul.grid__list li {
            width: 100% !important;
        }
    }
    .info {
        ul {
            margin: 10px 0 0 15px;
        }
        li {
            list-style-type: disc;
        }
    }

    .product__view {
        padding: 20px;
        overflow: hidden;
    }
    a {color: #bbb;}
    .button {
        height: 25px;
        min-width: 120px;
        text-align: center;
        line-height: 25px;
        color: #666;
        border: 1px solid #666;
        background: none;
    }
    .product__scroll {
        width: 355px;
        height: 560px;
        float: left;
        overflow: hidden;
        overflow-y: auto;

        .product__description {
            height: inherit;
            width: 320px;
        }
        h2.product__title {
            color: #fff;
            font-size: 2.5rem;
            line-height: 27px;
            margin-bottom: 40px;
            text-transform: uppercase;
            font-weight: 300;
        }
        .product__headline {
            border-bottom: 1px dotted #bbb;
            padding-bottom: 10px;
            line-height: 21px;
            margin-bottom: 10px;
            @include clearfix;

            .product__rating {
                float: left;
                width: 100%;

                .recommend {
                    float: right;
                }
            }
            .product__recommended {
                float: right;
                font-size: 1.1rem;
            }
        }
        .product__price {
            display: block;
            padding-top: 0;
        }
    }
    ul.thumbnailsList li {
        border: 1px solid transparent;
        width: 42px;height: 53px;

        img {width: 42px;height: 53px;}
        &.active {
            border: 1px solid #666 !important;

        }
    }
}
.product__size {
    @include clearfix;
    margin-bottom: 20px;
    @include mobile {
        margin: 0 2% 10px 2%;
    }
    .name {
        width: 100%;
        display: inline-block;
        padding-bottom: 5px;
        font-size: 1.2rem;
    }
    .sortSelect {
        float: left;
        width: 85px;
        height: 28px;
        border-color: #fff;

        select {
            color: #fff;
            font-weight: 700;
            line-height: 28px;

            option {
                color: #bbb;
            }
        }
    }
    ul.sizeList {
        @include mobile {
            width: 100%;
            display: block;
            border: 1px solid #444;
            overflow: hidden;

            .sizeList__item {
                float: left;
                width: 20%;
                text-align: center;
                a {
                    border-right: 1px solid #444;
                    display: block;
                    line-height: 15px;
                    padding: 6px 11px;
                    color: #bbb;
                    &.active {
                        background: #444;
                    }
                }
            }
        }
    }
}




/*  =FILTER MOBILE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.popupFilterMobile {
    @include desktop {display: none;}
    width: 98%;
    height: 400px;
    overflow: hidden;
    padding: 1%;
    background-color: #1a1a1a;
    border: 1px solid #666;

    .filter__header {
        color: #fff;
        font-size: 1.3rem;
        display: inline-block;
        padding: 20px 0 30px 0;
    }
    .row {
        padding: 15px 0 35px 0;
        text-align: right;
    }
    .buttonWrap {
        //width: 100%;
        padding: 20px 5px 40px 0;
        text-align: right;
        span {
            color: #fff;
        }
    }
    .button {
        display: inline-block;
        text-align: center;
    }
}




/*  =VIDEO POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.productVideoPopup {
    width: 560px;
    height: 390px;
    padding: 20px 15px 0 15px;
    background-color: #1a1a1a;
}




/*  =BUY NOW PRODUCT POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.buyNowPopup {
    // width: 400px;
    // max-width: 400px;
    // background: #000;
    // display: inline-block;
    // box-sizing: border-box;
    width: 300px;
    max-width: 300px;
    background: #000;
    display: table;
    box-sizing: content-box;
    padding: 40px 20px 20px;
    // margin: 0 auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);

    p {
        // padding: 30px;
        color: #bbb;
        font-size: 1.4rem;
    }
}




/*  =ARTICLE POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.articlePopup {
    width: 960px;
    height: auto;
    min-height: 400px;
    padding: 40px;
    background: #fff;
    color: #000 !important;
    font-size: 1.2rem;
    position: absolute;
    bottom: auto;

    @include clearfix;


    .article__cms__content {
        margin-top: 40px;
        strong:first-child {
            font-size: 1.5em;
            margin-bottom: 20px;
            display: inline-block;
            font-weight: 400;
        }
    }
    .article {
        width: 75%;
        float: none;
        //@include clearfix;

        .article__cms__content {
            color: #000 !important;
            .video__thumbnail {
                margin-bottom: 50px !important;
                display: block;
            }
        }
    }
    a {
        color: #000 !important;
    }
    .article__title {
        color: #000 !important;
        margin-bottom: 30px;
        font-weight: 400 !important;
    }

    .article__headline {
        display: none;
    }
    .article__short {
        color: #000;
        font-weight: 400 !important;
        margin-top: 0 !important;
    }
    .printHeader {
        position: relative;

        .printPrintPopup {
            position: absolute;
            right: 80px;
            top: 30px;
            cursor: pointer;

            .printIcon  {
                display: inline-block;
                float: right;
                margin-left: 15px;
                @include sprite($print);
            }
        }
        .closePrintPopup {
            position: absolute;
            right: 0;
            top: 30px;
            cursor: pointer;

            .closeIcon {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-left: 10px;
                cursor: pointer;
                z-index: 999999;
                background: url("../img/ModalDialogClose.png");
            }
        }
    }
    .closePopup {
        display: none;
    }
}

/* =POPUP NOWELLON
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.global-popup-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    opacity: 0.8;
    filter:alpha(opacity=80);
    z-index: 9000;
    display: none;
    transform: compact(translateZ(0));
    &.js-overlay-visible { display: block; }
}
.global-popin-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9001;
}
.global-popin-scroller {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    outline: none;
}
.global-popin-centered {
    display: flex;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.sharePopup {
    color: white;
}




/* =SHARE POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.shareContentPopup {
    width: 540px;
    height: 360px;
    padding: 50px;
    background-color: #0d0d0d;

    h3.sharePopupTitle {
        font-size: 1.5rem;
        padding-bottom: 50px;
    }
    .sharePopupText {
        color: #bbb;
        font-size: 1.2rem;
        border-bottom: 1px solid #666;
        padding-bottom: 30px;
    }
    .sharePopupButton {
       color: #b30022;
       height: 32px;
       line-height: 32px;
       text-align: center;
       border: 1px solid #b30022;
       background-color: transparent;
       margin: 10px auto;
    }
    .shareButtonWrapper {
        text-align: center;
        padding-top: 20px;
    }
}




/* =LOGOUT POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.logoutPopup {
    width: 620px;
    height: 240px;
    padding: 50px;
    color: #fff;
    background-color: #0d0d0d;
    line-height: normal;

    h2 {
        font-size: 1.6rem;
    }
    p {
        color: #bbb;
        font-size: 1.2rem;
        padding: 25px 0 30px 0;
    }
    .logoutButtonSection {
        text-align: center;
        padding-top: 30px;
        border-top: 1px solid #666;
    }
    .button {
        background: none;
    }
}



/* =underage POPUP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

.underagePopup {
    height: 150px;
    background-color: #000 !important;
    color: #bbb;
    .popupContent {
        padding: 30px !important;
    }
    .message {
        font-size: 1.4rem !important;
        @include mobile {
            font-size: 1.1rem !important;
        }

    }
}
