﻿
/*	=RR PAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/

.rating__product {
    @include clearfix;
    @include mobile {display: none;}
    .product__tile {
        width: 100%;
        height: auto;
        float: none;
        background: none;
        @include clearfix;


        .product__packshot {
            float: left;
            width: 35%;
            .product__quickView {
                display: none;
            }
        }
        .product__shop {
            float: left;
            width: 60%;

            .product__title {
                margin: 20px 0 40px 0;
            }
            .contentWrap {
                position: static;
                width: auto;
                .product__description {
                    display: inline-block;
                    margin-top: 20px;
                    margin-bottom: 60px;
                    font-size: 1.2rem;
                    ul {
                        list-style: disc;
                        margin-left: 15px;
                    }
                }
                .product__price {
                    border-top: 1px dashed #666;
                    width: 100%;
                    display: block;
                    padding-top: 10px;
                    padding-bottom: 0;
                }
            }
        }
    }
    .writeTitle {
        font-size: 1.6rem;
    }
}
.form-block {
    @include mobile {margin: 0 2%;}
    position: relative;

    &.form-block-2 .form-input-wrap  {
        @include desktop {width: 66%;}
    }
    &.form-block-1 {

    }
    .form-warning {
        font-size: 1.4rem;
        color: #b30022;
        position: absolute;
        top: -50px;
        line-height: 24px;

        .icon {
            float: left;
            @include sprite($error);
        }
        .mailPopup & {
            position: static;
            margin-bottom: 20px;
        }
        @include mobile {
            text-align: center;
            color: #b30022;
            font-size: 1.2rem;
            position: static;
            .icon {display: none;}
        }
    }
}
.form-group {
    margin-bottom: 20px;
    overflow: hidden;

    .control-label {
        float: left;
        width: 20%;
        color: #fff;
        height: 30px;
        line-height: 20px;
        font-size: 1.2rem;
        text-align: right;
        padding-right: 20px;
        @include mobile {
            width: 100%;
            text-align: left;
            float: none;
            color: #bbb;
            font-size: 1.3rem;
        }
    }

    &.rating-group {
        @include mobile {
            .control-label {
                width: auto;
                height: auto;
                min-width: 60px;
                float: left;
                padding-right: 0;
            }
            .form-input-wrap.size1 {width: 100%;}
            .form-input-wrap.size2 {width: 100%;}
            .control-agree .input-checkbox {
                float: none !important;
            }
        }
    }

    &.mobileOnly .recommendInfo {
        @include mobile {
            .radioItem {
                margin-right: 10px;
            }
            .input-checkbox {
                margin: 0 !important;
                margin-right: 7px !important;
                float: none !important;
            }
        }
    }

    .form-input-wrap {
        float: left;
        width: 80%;
        @include mobile {
            width: 100%;
            float: none;
        }
        &.size1 {
            width: 20%;
        }
        &.size2 {
            width: 39%;
        }
        &.errorPosition .control-error {
            text-align: right;
            @include mobile {text-align: left;}
        }
        &.selectStyle {
            @include mobile {
                float: left;
                width: 30%;
            }
            .control-error {
                display: block;
                float: left;
                width: 100%;
                padding-top: 5px;
            }
        }
        .control-input {
            width: 100%;
            display: block;
            height: 30px;
            padding: 0 6px;
            line-height: 30px;
            background: #fff;
            border: 1px solid #bbb;
            color: #666;
            font-weight: 400;
        }
        .control-textarea {
            width: 100%;
            display: block;
            height: 80px;
            padding: 6px;
            background: #fff;
            border: 1px solid #bbb;
            color: #666;
            font-weight: 400;
        }
        .control-text {
            color: #4c4345;
            display: block;
            margin-top: 15px;
            font-size: 1.2rem;
            @include mobile {
                color: #bbb;
                margin-top: 10px;
            }
        }
        .input-checkbox {
            display: inline-block;
            margin-right: 5px;
        }
        .control-agree {
            font-size: 1.2rem;
            color: #fff;
            display: inline-block;
            a {
                text-decoration: underline;
                color: #bbb;
                margin-left: 5px;
            }
            &.color {
                color:#666;
                @include mobile {color: #bbb;}
            }
            @include mobile {
                width: 100%;
                display: block;
                padding-top: 10px;
                font-size: 1.3rem;
                color: #bbb;

                .input-checkbox {
                    float: left;
                    margin-bottom: 30px;
                    margin-right: 10px;
                    margin-top: 4px;
                }
                .mobileOnly {display: inline;}
            }
        }
        .form-text {
            font-size: 1.2rem;
            padding-right: 10px;
            color: #fff;
            @include mobile {
                font-size: 1.4rem;
                color: #bbb;
            }
        }
        .control-error {
            display: block;
            color: #b30022;
            font-size: 1.2rem;
            @include mobile {margin: 15px 0 0 0;font-size: 1.1rem;}
        }
        .sortSelect {
            width: 250px;
            height: 25px;
            float: left;
            border: 1px solid #bbb;
            margin-right: 60px;
            select {
                line-height: 25px;
            }
            @include mobile {
                width: 100px;
                background: #fff;
                height: 35px;
            }
        }
    }
}
.blockStyle {
    @include mobile {
        border: 0;
    }
}
.blockStyle .block__title {
    @include mobile {
        margin: 10px 0;
        font-size: 1.4rem;
    }
}

.selectTitle {
    @include mobile {
        float: left !important;
    }
}
.lineSpacer {
    display: none;
    @include mobile {
        height: 1px;
        background: #666;
        display: block;
        width: 100%;
    }
}
.mandatoryInfo {
    float: right;
    @include mobile {display: none;}
}
.agreeLabel {
    @include mobile {display: none !important;}
}
.info-message {
    border-top: 1px dotted #bbb;
    border-bottom: 1px dotted #bbb;
    padding: 35px 0;
    color: #666;
    font-size: 1.2rem;
    @include mobile {
        border-top: 0;
        border-bottom: 1px solid #666;
        padding: 0 2% 10px 2%;
        color: #bbb;
        line-height: 20px;
    }
}
.buttons-control {
    padding-top: 20px;
    padding-bottom: 50px;
    @include clearfix;
    @include mobile {display:none;}
    .block {
        float: left;
    }
    .block1 {
        width: 220px;
        // @include mobile {width:50%;text-align: center;}
        @include mobile {width:100%;text-align: center;}
        a.simple-button {
            color: #b30022;
            border: 1px solid #b30022;
        }
    }
    .block2 {
        width: 475px;
        text-align: center;
    }
    .block3 {
        width: 220px;
        float: right;
        text-align: right;
        // @include mobile {width:50%;text-align: center;}
        @include mobile {width:100%;text-align: center; margin-top: 20px;}
        a.simple-button {
            color: #666;
        }
    }
    a.btn {
        text-decoration: underline;
        color: #bbb;
        font-size: 1.2rem;
        @include mobile {
            display: block;
            padding-top: 10px;
            font-size: 1.1rem;
        }
    }
    .simple-button {
        font-size: 1.2rem;
        font-weight: 100;
        height: 25px;
        line-height: 25px;
        border: 1px solid #666;
        text-align: center;
        display: inline-block;
        padding: 0 50px;
        cursor: pointer;
    }
    .pipe {
        font-size: 1.4em;
    }
}
