﻿/*  =FOOTER
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

footer.desktop {
    background: #b30022;
    @include mobile {display: none !important;}

    .row {
        width: 940px;
        margin: 0 auto;
        overflow: hidden;
    }
    .siteMap {
        font-family: 'GothamLight';
        overflow: hidden;
        padding: 40px 0 34px 0;
        font-size: 14px;
        border-top: 1px dashed #e6aaaa;
        //border-bottom: 1px solid #e6aaaa;

        .twoLists {
            float: left;
            width: 23.404255319148934%;
            margin-right: 20px;

            .third {
                margin-bottom: 8px;
            }
        }
        > ul {
            float: left;
            width: 23.404255319148934%;
            margin-right: 20px;
        }
        ul:last-child {
            margin-right: 0;
        }
        li.first {
            font-size: 12px;
            padding-bottom: 20px;
            font-weight: 500;
        }
        li {
            padding-bottom: 13px;
        }
    }

    .signUp {
        float: right;
        color: #fff;
        font-size: 1rem;
        // display: block;
        display: none;
    }

    .socialMediaLinks {
        .signUp {
            display: none;
            line-height: 35px;
            .shortText {
                font-size: 1.1rem;
            }
            .signUpButton {
                display: inline-block;
                border: 1px solid #fff;
                line-height: 28px;
                text-align: center;
                padding: 0 35px;
                margin-left: 30px;
            }
        }
    }

    .links {
        font-size: 1.2rem;
        overflow: hidden;
        padding: 10px 0 20px 0;

        ul {
            float: left;

            li {
                display: inline-block;
                padding-right: 30px;
                color: #fff;
                color: #e6aaaa;
                a { color: #e6aaaa; }
                a.everyday { padding-right: 15px; }
                a.estore   { padding-left: 15px;  }
            }
        }

        .bbblogo {
            float: right;
        }
    }

    .terms {
        border-top: 1px dashed #e6aaaa;
        font-size: 1.2rem;
        font-family: arial;
        ul {
          float: left;
          li {
            display: inline-block;
            padding-right: 40px;
            line-height: 45px;

            a {
              color: #e6aaaa;
            }
          }
    }
    span {
      display: inline-block;
      line-height: 45px;
      color: #e6aaaa;
    }
  }
}




/*  =FOOTER MOBILE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

@include mobile {
footer.mobileOnly {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    color: #de9092;
    background: #b30022;

    a {
        color: #de9092;
    }
    .row {
        width: inherit;
        padding: 22px 0;
        border-bottom: 1px dashed #bbb;

        &:last-child {
            border: 0;
        }
    }
    .certificate {
        text-align: center;
        img {
            width: 33%;
        }
    }
    .siteMap {
        text-align: center;
        font-size: 1.3rem;
        font-weight: 400;
        padding: 0 10px;
        .row & {
            padding: 10px 0 !important;
        }
        > ul {
            display: none !important;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            text-transform: uppercase;
            padding: 0 10px;
            &:nth-child(1) {
                display: inline-block !important;
            }
            &:nth-child(2) {
                display: inline-block !important;
            }
            li {
                margin: 8px 0;
                &.first {
                    display: none;
                    margin: 0 0 15px 0;
                    color: #bbb;
                }
                &:nth-of-type(2) {
                    margin-top: 0;
                }
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
    .privacyLink {
        text-align: center;
        font-size: 1.1rem;
        font-weight: 400;

        li {
            display: inline;
            line-height: 20px;

            &:after {
                content: "|";
                margin: 0 3px;
                font-size: 1.4rem;
            }
            &:last-child:after {
                content: ""
            }
        }
        .privacyLink__copy {
            display: inline-block;
            margin: 30px 0 10px 0;
        }
    }
    .pgFamily {
        text-align: center;
        display: inline-block;
        .title {
            display: block;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }
        img {
            vertical-align: middle;
            margin-left: 10px;
            &:first-child {
                margin-right: 15px;
            }
        }
    }
    .followUs {
        padding-left: 10px;
        padding-right: 10px;
        .title {
            font-size: 1.3rem;
        }
        ul {
            float: right;
        }
        li {
            display: inline-block;
            .symbol {
                vertical-align: middle;
                font-size: 4rem;
                color: #fff;
                line-height: 14px;

                &.xsymbol {
                    font-size: 2.4rem;
                }
            }
        }
    }
    .selectCountry {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.3rem;
        .countryLink {
            float: right;
            padding-right: 15px;
            background: url("../img/RightArrowNavigationIcon.png") no-repeat right center;
            background-size: 8%;
        }
    }
    .signUp {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 1.3rem;
        vertical-align: middle;
        overflow: hidden;
        display: none;

        .shortText {
            width: 70%;
            float: left;
            color: #fff;
        }
        .signUpButton {
            vertical-align: middle;
            border: 1px solid #de9092;
            padding: 5px 15px;
            font-size: 1.1rem;
            float: right;
            font-weight: 300;
        }
    }

} // END FOOTER
} // END MOBILE


.ot-sdk-show-settings {
    border: inherit !important;
    color: inherit !important;
    font-size: inherit !important;
    letter-spacing: inherit;
    padding: 0 !important;

    &:hover {
        background-color: inherit !important;
        border-bottom: inherit !important;
    }
}
