﻿
/*	=VIDEO PAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/
.collectionProductGroup {
    .video__article {
        width: 48.6%;
        float: left;
        min-height: 370px;
        position: relative;
        &:nth-child(even) {
            margin-right: 0;
        }
        @include mobile {
            margin-right: 2%;
            position: relative !important;
        }
        .video__title {
            min-height: 100px;
        }
        .video__recommend {
            position: absolute;
            bottom: 20px;
        //     left: 50%;
        //     transform: translateX(-50%);
        }
        .button {
            @include mobile {
                position: absolute;
                bottom: 10%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}

.video__list {
    width: 100%;
    position: relative;
    @include clearfix;
}
.video__article {
    width: 350px;
    padding: 0 20px 20px 20px;
    background-color: #0d0d0d;
   // float: left;
    margin-bottom: 20px;
    margin-right: 20px;
    // &:nth-child(2n+2) {
    //     margin-right: 0;
    // }


    @include mobile {
        text-align: center;
        float: none;
        position: static !important;
        top: auto !important;
        left: auto !important;
        width: auto;
        margin-right: 0;
    }

    .video__tag {
        border-bottom: 1px dashed #666;
        display: block;
        color: #bbb;
        text-align: center;
        font-size: 1.4rem;
        padding: 10px 0;

        @include mobile {
            margin-bottom: 10px;
        }
    }
    .video__title {
        font-size: 1.5rem;
        padding: 15px 0;
        cursor: pointer;
        text-transform: uppercase;
        @include mobile {
            padding: 5px 0;
        }
    }
    .video__picture {
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        @include mobile {margin-bottom: 0;}

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -25px;
            margin-top: -25px;
            @include sprite($-video-thumbnail);
        }
        img {
            max-width: 100%;
        }
    }
    .video__recommend {
        color: #bbb;
        padding-top: 15px;
        font-size: 1.2rem;
    }
    .button {
        @include mobile {
            display: inline-block;
            color: #bbb;
        }
    }
}
