
.tab {
    border-bottom: #bbb solid 1px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #bbb;

    @include mobile {
        border: 1px solid #666;
        margin: 0 auto;
        text-align: center;
        overflow: hidden;
        display: block;
    }
    li {
        font-size: 1.1rem;
        text-transform: uppercase;
        text-align: center;
        float: left;
        height: inherit;
        line-height: 28px;
        display: inline-block;
        margin-bottom: -1px;
        min-width: 20%;

        @include mobile {
            border: none;
            width: 50%;
            background-color: #0d0d0d;
        }
        a {
            padding: 10px 25px;
            display: block;
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.5;
            color: #bbb;
            cursor: pointer;
            @include mobile {
                text-transform: none;
                font-size: 1.14rem;
                font-weight: 300;
            }
            > span {
                margin-left: 5px;
            }
        }
        &.active {
            border: #bbb solid 1px;
            border-bottom: #1a1a1a solid 1px;
            @include mobile {
                background: #666;
                border: 0;
            }
            a {
                color: #fff;
            }
        }
    }
}

.tabMessage {
    color: #fff;
    display: block;
    margin: 20px 0 200px 0;
    font-size: 13px;
    font-weight: 500;
}
