﻿
/*  =HEADER
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

body.nav-toggle {
    @include mobile {
        .menuSection {overflow: visible;}
    }
}

header {
    width: 100%;

    .menuSection {
        height: 100px;
        position: relative;
        @include mobile {
            height: 65px;
            overflow: hidden;
            transition: height 1s;

            &.searchActive {
                height: 120px;
            }
        }
    }
    .row {
        width: 940px;
        margin: 0 auto;
        position: relative;
        @include mobile {
            width: 96%;
            margin: 0 2%;
        }
    }
    .row-fluid {
        width: 100%;
        background: #b30022;
        @include mobile {display: none;}
    }
    .brandCountrySection {
        height: 50px;
        line-height: 50px;
        overflow: hidden;
        font-family: arial;
        font-size: 1.14rem;
        color: #e6aaaa;
        letter-spacing: 0.6px;

        a {
            color: #e6aaaa;
        }

        .pgLinks {
            height: inherit;
            float: left;
            ul li {
                display: inline-block;
                margin-right: 30px;
                img {
                   vertical-align: middle;

                   &.pgShopImg {
                        width: 75%;
                   }
                }
            }
        }
        .signinLinks {
            display: none;
            float: right;
            letter-spacing: normal;
            ul li {
                float: left;
            }
            .line {
                display: inline-block;
                padding: 0 10px;
            }
            .welcome {
                color: #fff;
                padding-right: 10px;
                font-size: 1.4rem;
            }
        }
        .country__selector {
            float: right;
            margin-left: 100px;
            text-transform: uppercase;
            letter-spacing: normal;
            a {cursor: pointer;}

            .caret {
                border-top: 7px solid #fff;
                border-right: 5px solid transparent;
                border-left: 5px solid transparent;
                display: inline-block;
                width: 0;
                height: 0;
                vertical-align: middle;
                position: relative;
                top: -2px;
                margin-left: 5px;
            }
        }
    }
    h1 {
        position: absolute;
        top: 0;
        left: 0;
        @include mobile {
            img {height: 65px;}
        }
    }
    .tips-head {
        @include mobile {
            h2 {
                color: #fff;
                font-size: 2rem;
                line-height: 28px;
                margin: 0 0 20px;
                font-weight: 400;
            }
        }
    }
    .searchPanel {
        position: absolute;
        right: 0;
        top: 20px;
        @include mobile {
            right: 0px;
            top: 75px;
            width: 100%;
        }

        .searchText {
            padding-left: 30px;
            height: 25px;
            width: 215px;
            @include mobile {
                color:#fff;
                width: 100%;
                padding: 0 60px 0 10px;
                height: 43px;
                line-height: 43px;
                border:1px solid #444;
            }
        }
        .searchButton {
            width: 25px;
            height: 23px;
            position: absolute;
            left: 3px;
            top: 1px;
            font-size: 0;
            text-indent: -9000em;
            background: url("../img/SearchBoxImage.png") no-repeat center center;

            @include mobile {
                width: 40px;
                height: 42px;
                left: auto;
                right: 0;
                border-left: 1px solid #444;
                background: url("../img/MainMenuIcon.png") no-repeat center center;
                background-size: 26%;
            }
        }
    }
    .breadcrumbSection {
        overflow: hidden;

        .print {
            float: right;
        }
        .breadcrumb {
            float: left;
            margin: 15px 0 20px 0;
            padding: 8px 0;
            color: #666;
            line-height: 14px;
            @include desktop {
                width: 80%;
            }
            @include mobile {
                line-height: 20px;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            li {
                float: left;
                position: relative;
                &:first-child a {
                    padding-left: 0;
                    margin-left: 0;
                }
                &:after {
                    content: ">";
                    margin: 0 5px;
                    font-size: 1.2rem;
                    display: inline-block;
                }
                &:last-child a {
                    @include desktop {color: #bbb;}
                    // @include mobile {
                    //     font-size: 2rem;
                    //     color: #fff;
                    // }
                }
                &:last-child:after {
                    content: "";
                }
                a {
                    color: #666;
                    font-family: arial;
                    font-size: 11px;
                    text-transform: uppercase;
                    outline: none;
                    margin: 0 5px 0 5px;
                    @include mobile {margin-left: 0;}
                }
            }
        }
    }
}
