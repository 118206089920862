﻿/*	=SITEMAP
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/

.sitemap__page__title {
    font-size: 4em;
    font-weight: 300;
    @include mobile {padding: 0 2%; display: none;}
}
.sitemap__column {
    float: left;
    width: 50%;
    &:last-child {
        .sitemap__sub-subtitle {
            display: none;
        }
        div:first-child {
            .sitemap__subtitle {
                display: none;
            }
            .sitemap__block {
                padding-left: 0;
                .sitemap__list {
                    li {
                        padding-left: 0;
                        a {
                            font-size: 1.4rem;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    @include mobile {
        width: 100%;
        float: none;
        padding: 0 2%;
        &:last-child {
            div:first-child  {
                .sitemap__list {
                    // display: block;
                    margin-left: 35px;
                    li a {
                        font-size: 1.4rem !important;
                        padding: 0;
                    }
                }
            }
            .sitemap__subtitle {
                display: none;
            }
            .sitemap__sub-subtitle {
                display: block;
            }
        }
    }
    .sitemap__title {
        margin-top: 40px;
        @include mobile {
            margin-top: 0;
        }
    }
}
.sitemap__title {
    font-size: 2.4rem;
    font-weight: 300;
    margin-bottom: 40px;
    margin-top: 40px;

    @include mobile {
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
.sitemap__subtitle {
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 20px;

    @include mobile {
        margin: 23px 0;
        a {
            color: #fff;
            font-size: 1.4rem;
            margin-left: 15px;
        }
    }
}
.sitemap__sub-subtitle {
    font-size: 1.4rem;
    @include mobile {
        a {
            color: #b2001d;
            font-size: 1.3rem;
            margin-left: 35px;
        }
    }
}
.sitemap__list {
    @include mobile {
        margin-left: 40px;
    }
    li {
        font-size: 1.4rem;
        padding-left: 20px;
        // margin: 12px 0;
        margin: 20px 0;

        a {
            color: #bbb;
            @include mobile {
                color: #fff;
                font-size: 1.2rem;
            }
        }
    }
}
.sitemap__block {
    padding-left: 16px;
    margin-bottom: 30px;
    @include mobile {
        padding-left: 0;
    }
}
