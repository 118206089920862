

/*	=RATING AND REVIEWS
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/
.goBack {
    color: #666;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin: 23px 0;
    display: inline-block;
    position: relative;
    &:before {
        content: "\2B05";
        float: left;
        font-size: 2em;
        margin-top: -10px;
        margin-right: 10px;
    }
    // &:first-letter {
    //     font-size: 3rem;
    //     margin-top: 5px;
    //     display: block;
    //     position: absolute;
    //     // top: 50%;
    //     // left: 50%;
    //     // transform: translate(-50%, -50%);
    // }
}
.reviewHeader {
    h5 {
        padding-bottom: 20px;
        padding-top: 10px;
        margin: 0 0 0 0 !important;
        color: #ccc;
    }
    p {
        padding-bottom: 20px;
        color: #666;
        font-size: 1.1rem;
    }
    .product__rating {
        margin-bottom: 6px;
        span {
            color: #bbb;
            font-size: 1.1rem;
            margin-left: 10px;
            &:first-child {
                margin-right: 10px;
                margin-left: 0;
                font-size: 14px;
                float: left;
            }
        }
    }
    .recommended {
        color: #bbb;
        font-size: 1.1rem;
    }
}
.review__button {
    @include desktop {display: none;}
    @include mobile {
        display: block;
        margin: 40px 2% 0;
        text-align: center;
        li {
            width: 50%;
            text-align: center;
            display: inline-block;
        }
        a {
            display: inline-block;
            line-height: 30px !important;
            padding: 0 8%;
            font-size: 1.12rem;
            color: #bbb;
            border: 1px solid #444;
            width: 96%;
        }
    }
}
.reviewTable, .reviewPopup {
    margin-top: 20px;
    .review__button {
        @include mobile {
            ul {
                margin: 0;
                li {
                    margin-left: 0 !important;
                    width: 50%;
                    a {
                        font-size: .77rem !important;
                        padding: 0;
                        width: 90%;
                    }
                }
            }
        }
    }
    .reviewDelete {
        display: block;
        overflow: auto;
        span {
            float: left;
            margin-top: 10px;
            font-size: 1.4rem;
            color: #4c4c4c;
            cursor: pointer;
            @include mobile {
                text-transform: uppercase;
                color: #fff;
                margin-left: 20px;
                margin-bottom: 20px;
                font-size: .77rem;
            }
        }
        .whatsThis {
            @include sprite($whats-this);
            margin-left: 8px;
            margin-top: 12px;
            @include mobile {
                margin: 8px 0 0 35px;
            }
        }
    }
    .reviewContent {
        // padding: 20px 0;
        > a.button {
            display: table;
            margin: 20px auto;
            color: #666;
            &:hover {
                color: #fff;
            }
        }
        @include mobile {
            font-size: 1.2rem;
            line-height: 20px;
            // line-height: 18px;
            padding: 0 10px;
            p {
                font-size: 1.2rem;
            }
        }
        .reviewToolbar {
            margin: 45px 0 5px;
            padding-bottom: 50px;
            position: relative;
            min-height: 100px;
            display: block;
            .selectWrapper {
                position: relative;
                width: 20%;
                float: left;
                display: inline-block;
                margin-right: 20px;
                span {
                    text-transform: uppercase;
                    position: absolute;
                    left: 0;
                    top: -20px;
                    font-size: 1.2rem;
                    line-height: 12px;
                }
                .sortSelect {
                    select {
                        background: none;
                        font-family: 'GothamLight';
                        font-size: 1.2rem;
                        border: 0;
                        color: #bbb;
                        width: 100%;
                        height: inherit;
                        float: left;
                        line-height: 32px;
                        padding-left: 5px;
                    }
                }
            }
            .reviewWrite {
                float: right;
                font-size: 1.2rem;
                .button {
                    padding: 8px 45px;
                }
            }
        }
        .reviewTile {
            border-top: 1px dashed #666;
            padding: 20px 0;
            display: table;
            width: 100%;
            @include mobile {
                padding: 5px 5px 5px 15px;
                display: block;
                margin-bottom: 5px;
                border-top: none !important;
                border-bottom: 1px solid #666;
            }
            h4 {
                font-size: 1.6rem;
                font-weight: 400;
                color: #fff;
                text-transform: uppercase;
                margin: 10px 0 5px;
                @include mobile {
                    font-size: 1.1rem;
                    text-transform: none;
                    color: #bbb;
                }
            }
            .reviewNick {
                display: table-cell;
                width: 25%;
                vertical-align: top;
                @include mobile {
                    display: block;
                }
                h4 {
                    margin: 0 0 5px;
                }
                .reviewAuthorLoc {
                    font-size: 1.3rem;
                    color: #ccc;
                    margin-bottom: 10px;
                    @include mobile {
                        margin-bottom: 0;
                        font-size: 1.2rem;
                        color: #bbb;
                    }
                }
                .product__rating {
                    margin-bottom: 5px;
                    .name {
                        float: left;
                        font-size: 1.3rem;
                        color: #ccc;
                        width: 50px;
                    }
                }
            }
            .reviewSubject {
                display: table-cell;
                width: 75%;
                @include mobile {
                    display: block;
                    width: 100%;
                }
                .product__rating {
                    margin-bottom: 20px;
                    display: inline-block;
                    float: left;
                    @include mobile {
                        display: block;
                        float: none;
                        margin-bottom: 0;
                        &:first-of-type {
                            margin-bottom: 15px;
                        }
                        .name {
                            display: block;
                        }
                    }
                }
                .reviewDate {
                    margin-left: 35px;
                    font-size: 1.1rem;
                    color: #666;
                    display: block;
                    float: left;
                    @include mobile {
                        float: right;
                        color: #bbb;
                        font-size: 1.1rem;
                    }
                }
                h4 {
                    clear: both;
                }
                .reviewContent {
                    color: #bbb;
                    // font-family: Gotham A;
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 22px;
                    padding: 12px 0;
                    // text-align: justify;
                    text-align: left;
                    @include mobile {
                        font-size: 1.1rem;
                        font-weight: normal;
                        padding: 0;
                    }
                    p {
                        @include desktop {
                            display: none;
                        }
                    }
                }
            }
            .reviewFooter {
                display: block;
                margin-top: 50px;
                @include mobile {
                    margin-top: 10px;
                }
                a {
                    color: #666;
                    &.reviewed {
                        color: #fff;
                        cursor: auto;
                    }
                }
                ul {
                    width: 100%;
                    display: inline-block;
                    @include mobile {
                        margin-left: 15px;
                    }
                    li {
                        margin: 0;
                        list-style: none;
                        color: #666;
                        font-size: 1.1rem;
                        @include mobile {
                            color: #bbb;
                            float: none;
                            font-size: 1rem;
                            display: block;
                        }
                        &:first-child {
                            float: left;
                            @include mobile {
                                float: none;
                                margin-bottom: 5px;
                            }
                            span {
                                cursor: pointer;
                                text-transform: uppercase;
                                &:first-of-type {
                                    &:after {
                                        content: " | ";
                                        @include mobile {
                                            content: "\00a0";
                                        }
                                    }
                                }
                            }
                            p {
                                display: inline-block;
                                &:after {
                                    content: "\00a0";
                                    margin-right: 5px;
                                }
                            }
                        }
                        &:last-child {
                            float: right;
                            @include mobile {
                                float: none;
                                display: block;
                                p {
                                    display: inline;
                                    font-size: 1rem;
                                }
                            }
                            span {
                                text-transform: uppercase;
                                a {
                                    @include mobile {
                                        // text-transform: none;
                                        text-decoration: underline;
                                        font-size: .688rem;
                                        line-height: 1;
                                    }
                                }
                                &:first-of-type {
                                    @include mobile {
                                        margin-left: 20px;
                                    }
                                    &:after {
                                        content: "\00a0";
                                        @include mobile {
                                            margin-left: 10px;
                                            margin-right: 10px;
                                            content: " | ";
                                            color: #666;
                                            display: inline-block;
                                        }
                                    }
                                    // &:before {
                                    //     content: "\00a0";
                                    // }
                                }
                            }
                        }
                    }
                }
                .reviewBadge {
                    display: block;
                    clear: both;
                    margin-top: 20px;
                    font-size: 1.4rem;
                    color: #fff;
                    @include mobile {
                        color: #bbb;
                        font-size: 1.2rem;
                        word-wrap: break-word;
                        margin-top: 0;
                    }
                }
            }
            .reviewResponse {
                margin-left: 32px;
                @include mobile {
                    margin-left: 0;
                }
                h4 {
                    @include mobile {
                        font-size: 1.2rem;
                    }
                }
                span {
                    color: #fff;
                    font-size: 1.3rem;
                    margin-right: 5px;
                    @include mobile {
                        color: #bbb;
                        font-size: 1.2rem;
                    }
                }
                p {
                    color: #bbb;
                    font-size: 1.2rem;
                    padding: 5px 0;
                    line-height: 1.6rem;
                }
            }
        }
    }
}

.mobileRating {
    @include mobile {
        padding: 2%;
        .recommended {
            padding: 5px 0;
        }
        .product__rating {
            &:first-child {
                .average-rating {
                    font-size: 1.4rem;
                }
            }
            .name {
                display: block;
                width: 100%;
                font-size: 1.2rem !important;
            }
        }
    }
}

// Review popup specific styles

.reviewPopup, .mailPopup {
    margin-top: 0;
    .reviewContent {
        h4 {
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 400;
            padding: 16px 3px;
            @include mobile {
                padding-left: 0;
            }
            &:nth-child(1) {
                @include mobile {
                    border-bottom: #666 solid 1px;
                    font-weight: 100;
                }
            }
        }
        .backButton {
            span {
                color: #666;
                font-weight: 300;
                font-size: 1.1rem;
                text-transform: uppercase;
                cursor: pointer;
                @include desktop {
                    display: none;
                }
            }
        }
        .reviewTile {
            border-top: none !important;
            border-bottom: 1px solid #666;
            margin-bottom: 25px;
            @include mobile {
                padding-left: 0;
                border: none;
            }
            .reviewNick {
                padding-right: 30px;
                h4 {
                    padding-top: 0;
                    border: none;
                    @include mobile {
                        padding-bottom: 0;
                        font-size: 1.2rem;
                        color: #bbb;
                    }
                }
                @include mobile {
                    width: 100%;
                }
                .product__rating {
                    @include mobile {
                        display: none;
                    }
                }
            }
            .reviewAuthorLoc {
                @include mobile {
                    display: none;
                }
                span {
                    &:nth-child(2) {
                        display: block;
                        @include mobile {
                            display: inline-block;
                        }
                    }
                }
            }
            .reviewSubject {
                h4 {
                    @include mobile {
                        text-transform: uppercase;
                        color: #bbb;
                        font-size: 1.3rem;
                        font-weight: 300;
                        padding-top: 0;
                    }
                }
                .product__rating {
                    float: left;
                }
                .reviewDate {
                    line-height: 1.9;
                    @include mobile {
                        color: #bbb;
                        font-weight: 300;
                    }
                }
                .reviewContent {
                    font-size: 1.2rem;
                    + span {
                        margin-bottom: 20px;
                        display: block;
                        @include mobile {
                            float: right;
                            color: #bbb;
                        }
                    }
                }
            }
        }
        .reviewButtons {
            @include mobile {
                clear: both;
                margin-left: 20px;
                margin-right: 20px;
                overflow: auto;
            }
            span {
                text-align: center;
                cursor: pointer;
                &.centered {
                    margin: 0 auto;
                    text-align: center;
                    float: none !important;
                    display: table;
                }
                @include mobile {
                    width: 45%;
                    // margin: 0 10px;
                }
                line-height: 1;
                &:nth-child(1) {
                    float: left;
                    border: 1px solid #b30022;
                    color: #b30022;
                }
                &:nth-child(2) {
                    float: right;
                    @include mobile {
                        color: #666;
                    }
                }
            }
            @include mobile {
                overflow: auto;
                margin-bottom: 25px;
            }
        }
    }
}

@include mobile {
    .writeRewievWrapper {
        &.previewActive {
            display: none;
        }
    }
}

.reviewPreview {
    overflow: auto;
}
.reviewSuccess {
    height: 300px !important;
}
.mailPopup {
    padding: 0 55px;
    h2 {
        font-size: 1.6rem;
        text-transform: uppercase;
    }
    h4 {
        font-size: 3.2rem !important;
        text-transform: uppercase;
        color: #fff;
    }
    .form-group {
        margin-left: 20px;
        label {
            display: block;
            margin-bottom: 5px;
        }
        &.invalid {
            color: #b30022;
            position: relative;
            label {
                // position: absolute;
                // left: 40px;
                // top: 0;
            }
        }
    }
}

.writeRewievWrapper {
    .product__packshot {
        img {
            width: 180px;
        }
    }
}
