input[type='submit'],
input[type='button'],
input[type='reset'] {
    border: 0;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type='text'],
input[type='email'],
input[type='password'] {
    background: none;
    line-height: 23px;
    color: $color-content;
}
textarea {
    outline: none;
    resize: none;
    overflow: auto;
}
// select {
//     border: 1px solid #666;
//     border-radius: 0;
//     font-size: 1.3rem;
//     margin-right: 10px;
// 	padding: 8px 6px;
// 	background: transparent;
// 	color: #666;
// 	appearance: none;
// }
.sortSelectWrap {
	width: 100%;
	clear: both;
	.sortSelect {
		float: left;
		width: 240px;
		@include mobile {
			width: 49%;
		}
		&:first-child {
			margin-right: 4%;
			@include mobile {margin-right: 2%;}
		}
	}
}
.formContainer {
	.formColLong,
	label,
	input[type='text'],
	input[type='email'],
	input[type='password'] { width: 100%; }

	input[type='text'],
	input[type='email'],
	input[type='password'] {
	    background: #fff;
	    color: #666;
	    border: 0 none;
	    padding: 5px 0;
	    text-indent: 10px;
	    line-height: 1.5;
	    font-size: 1.3rem;
	}
	.formColShort {
		width: 40%;
		margin-right: 10%;
		float: left;
		position: relative;
		padding-bottom: 20px;
	}
	.formColLong {
		position: relative;
		padding-bottom: 20px;
		@include desktop {padding-bottom: 0px;}
	}
	.formColLong,
	.formRowOne { clear: left; }
	label {
		display: block;
		font-size: 1.1rem;
		color: #fff;
		padding: 10px 0;
	}
	select {

		width: 240px;
	}
	.checkBox {
		label {
			font-size: 1.2rem;
			font-weight: normal;
			padding-right: 2.5%;
			color: #bbb;
			@include desktop {line-height: 10px;}
		}
		input[type='checkbox'] {
			float: left;
			margin: -1px 10px 0 0;
		}
		ul {
			padding-left: 35px;
			padding-top:5px;
		}
		ul li {
			list-style-type: disc;
			line-height: 17px;
		}
	}
	.disclaimer {
		padding-right: 2.5%;
		color: #fff;
		font-size: 1.2rem;
		margin-top: 5px;
		p {
			margin-bottom: 10px;
		}
		a {
			text-decoration: underline;
			color: #bbb;
		}
	}
	.button {
		width: 380px;
		margin: 10px 0;
	}
	.tooltip {
		font-size: 1.2rem;
		font-weight: 100;
		color: #666;
		padding: 10px 0;
		a { color: $color-golden-link; }
		&.hide {
			display: none !important;
		}
	}
	.tooltipTitle {
		text-decoration: underline;
	}
	.tooltipIcon {
		@include sprite($tooltip);
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		z-index: 99999;
		&:hover .tooltipText { display: block; }
	}
	.tooltipText {
		width: 240px;
		display: block;
		font-size: 1.3rem;
		color: #fff;
		font-weight: 500;
		text-decoration: none;
		margin-top: 10px;
	}
	.error {
		font-size: 1.2rem;
		color: #b30022;
		display: inline-block;
		padding: 0;
		margin-top: 5px;
	}
}

@include mobile {
	.formContainer {
		.formColShort {
			float: none;
			width: 100%;
			padding-bottom: 10px;
		}
		label {
			color: #bbb;
			font-size: 1.3rem;
			padding-bottom: 3px;
		}
		select {
			display: block;
			width: 100%;
			margin-bottom: 10px;
		}
		.checkBox label, .disclaimer { font-size: 1.2rem; }
		.button { width: 100%; }
		.sortSelectWrap {
			.sortSelect:last-child select {
				margin-right: 0;
			}
		}
	}
}
