﻿
/*  =MENU
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

@include mobile {
    body {
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        overflow-x: hidden;
    }

    body.nav-toggle {
        -webkit-transform: translate3d(-90%, 0, 0);
        transform: translate3d(-90%, 0, 0);

        nav.mainMenu {
            visibility: visible;
            opacity: 1;
            //right: -85%;
            // right: -290px;
            right: -320px;
        }
    }
}
.menuWrapper {
    @include desktop {
        min-width: 50%;
        float: right;
        margin-top: 50px;
    }
}
nav.mainMenu {
    // float: left;
    float: right;
    // margin-right: 42px;
    margin-right: 39px;

    @include mobile {
        width: 260px;
        position: absolute;
        //right: -85%;
        right: -290px;
        top: 10px;

        visibility: hidden;
        opacity: 0;
        transition: visibility .5s, opacity .5s linear;
        transition: -webkit-transform visibility .5s, opacity .5s linear;
        transform: translateZ(0);
    }

    a:hover {
        color: #000;
    }

    ul.mainMenu__list {
        float: right;

        @include mobile {
            border: 1px solid #444;
            width: inherit;
            .menuItem__title {
                display: block;
                line-height: 52px;
                padding-left: 15px;
            }
        }
    }

    li.menuItem {
        float: left;
        font-size: 1.25rem;
        position: relative;

        @include mobile {
            float: none;
            display: block;
            padding: 0 0 0 0;
            margin: 0;
            border-bottom: 1px solid #444;
            font-weight: 300;

            &:last-child {border:0;}
        }

        @include desktop {
            &:first-child {
                display: none;
            }
            .sub-menu {
                display: none;
            }
            &:hover {
                .sub-menu {
                    display: block;
                }
            }
        }

        .menuItem__title {
            @include desktop {
                display: inline-block;
                line-height: 30px;
                padding: 10px 21px 10px 17px;
            }
            @include mobile {
               color: #bbb;
               text-transform: uppercase;
            }
        }

        &:after {
            content: "";
            display: block;
            width: 6px;
            height: 5px;
            position: absolute;
            top: 22px;
            right: 0;
            background: url("../img/Dropdown_Menu_Arrow.png") no-repeat 0 0;

            @include mobile {
                width: 13px;
                height: 9px;
                top: 22px;
                right: 10px;
                background: url("../img/DownNavigationIcon.png") no-repeat 0 0;
                background-size: cover;
            }
        }

        &.active:after {
            @include mobile {
                width: 13px;
                height: 9px;
                top: 22px;
                right: 10px;
                background: url("../img/UpNavigationIcon.png") no-repeat 0 0;
                background-size: cover;
            }
        }

        &:first-child:after {
            display: none;
        }

        > a:hover {
            color: #fff;
            @include mobile {color:#bbb;}
        }

        &:hover:before {
            @include desktop {
                position: absolute;
                content: "";
                top: 87%;
                left: 50%;
                margin-left: -11px;
                z-index: 100;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 11px 11px 11px;
                border-color: transparent transparent #b30021 transparent;
            }
            @include mobile {display: none;}
        }

        &.lastElement {
            .sub-menu {
                width: 180px;
                left: 50%;
                margin-left: -90px;
                @include mobile {width: 100%;margin-left: 0;left:-40px;}

                &:after {
                    left: 40%;
                }
            }
        }

        &.firstElement {
            .sub-menu {
                width: 340px;
                //left: -192px;
                left: 50%;
                margin-left: -170px;
                padding: 20px 0;
                border: 0;
                z-index: 99;
                background: #b30022 url("../img/gradient_menu.jpg") no-repeat center top;
                @include mobile {width: 100%;background: #333;padding: 0; margin-left: 0;}

                &:after {
                    top: -6px;
                    left: 38%;
                }
                .sub-menu-area {
                    width: inherit;
                    height: 400px;
                    @include mobile {height: auto;}
                }
                .viewAllProducts {
                    font-size: 1.4rem;
                    padding: 20px 0 0 10px;
                    display: block;
                    clear: both;
                    @include mobile {display: none;}
                }
                li.subMenuColumn {
                    float: left;
                    width: 50%;
                    overflow: hidden;
                    border-right: 1px dashed #000;
                    padding-left: 10px;
                    height: 100%;
                    margin-left: 0;

                    @include mobile {
                        width: 100%;
                        height: auto;
                        float: none;
                        border-right: 0;
                        position: relative;

                        border-bottom: 1px solid #444;
                        margin: 0;
                        display: block;
                        line-height: 52px;
                        padding-left: 25px;
                        position: relative;

                        a:hover {
                            color: #fff;
                        }

                        &:after {
                            @include arrowRightMenu;
                        }

                        .mobileWrapper {
                            border-bottom: 1px solid #444;
                            margin-left: -25px;
                            padding-left: 25px;
                            position: relative;
                            &:after {
                                @include arrowRightMenu;
                            }
                        }
                    }

                    &:last-child {
                        border:0;
                    }
                    .menuItemTitle {
                        text-transform: uppercase;
                        display: block;
                        margin-bottom: 20px;
                        font-size: 1.4rem;
                        @include mobile {
                            font-size: 1.1rem;
                            margin: 0;
                        }

                        &.nextList {
                            margin-top: 60px;
                            @include mobile {margin-top: 0;}
                        }
                    }
                    ul {
                        float: none;
                        @include mobile {display: none;}

                        li {
                            margin-bottom: 15px;
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        } // FIRST ELEMENT

    }

    .sub-menu {
        display: block;
        width: 180px;
        background: #b30022;
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -90px;
        border-top: 5px solid #ddd;
        padding-top: 15px;
        padding-bottom: 20px;
        z-index: 10;
        clear: both;

        @include desktop {
            // nadpisuje JSowy height:0 z mobajla
            height: auto !important;
        }
        @include mobile {
            position: static;
            width: 100%;
            height: 0;
            background: #333;
            border: 0;
            padding: 0;
            margin-left: 0;
            overflow: hidden;
            text-transform: uppercase;
            transition: height 1s;
        }

        li.subMenuColumn {
            margin: 0 0 15px 10px;
            @include mobile {
                border-bottom: 1px solid #444;
                margin:0;
                display: block;
                line-height: 52px;
                padding-left: 25px;
                position: relative;
            }

            &:last-child {
                margin-bottom: 0;
                @include mobile {border:0;}
            }
            &.singleElement {
                margin-bottom: 0;
            }
            a {
                padding: 0;
                width: auto;
                text-transform: capitalize;
                font-size: 14px;
                outline: none;
                @include mobile {display:block;font-weight:400; text-transform: uppercase;font-size: 11px;}

                &:hover {
                    color: #000;
                    @include mobile {color:#fff;}
                }
            }
            &:after {
                content: "";
                display: none;
                @include mobile {
                    @include arrowRightMenu;
                }
            }
        }

        &.hide {
            @include desktop {display: none;}
        }
    }

    @include mobile {
        .userMenuLinks {
            text-align: center;
            margin-bottom: 15px;
            font-size: 1.2rem;

            .loginButton {
                display: inline-block;
                width: 114px;
                line-height: 29px;
                border: 1px solid #444;
                margin-right: 5px;
                color: #bbb;
            }
            .registerButton {
                display: inline-block;
                width: 114px;
                line-height: 29px;
                border: 1px solid #444;
                color: #bbb;
            }
        }
    }
}
