﻿/*  =HOME
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */
.horizontallyCentered {
    right: inherit !important;
    left: 50%;
    transform: translateX(-50%);
}
.verticallyCentered {
    left: inherit !important;
    bottom: 50%;
    transform: translateY(50%);
}
.specialPanels {
    margin-bottom: 20px;
    @include mobile {margin: 20px 0;}
    .panel {
        float: left;
        margin-right: 20px;
        @include mobile {margin-right:0;margin-bottom: 20px;position: relative;text-align: center;width: 100%;}

        &:last-child {
            margin: 0;
        }
        .panel__header {
            display: table;
            width: 300px;
            height: 358px;
            text-align: center;
            position: relative;
            @include mobile {
                display:inline-block;width: 100%;height:auto;padding: 0px;
                .vAlign {
                    display: inline-block;
                    width: 100%;
                }
            }

            .panel__image {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                @include mobile {
                    width: 100%;
                    position: static;
                }
            }
            .panel__title {
                font-size: 2.4rem;
                padding: 1px;
                @include mobile {
                    // display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    padding: 0 10px;
                }
            }
        }
        .panel__content {
            width: 300px;
            height: 163px;
            background: #000;
            text-align: center;
            padding: 15px 20px;
            position: relative;
            @include mobile {width: 100%;height: 100%;position:absolute;top:0;left: 0;background: transparent;padding:0;}

            p {
                text-align: left;
                font-size: 1.2rem;
                font-weight: 400;
                color: #bbb;
                line-height: 18px;
                @include mobile {display: none;}
            }
            .button {
                position: absolute;
                margin-left: -80px;

                @include desktop {left:50%;bottom:15px;}
                @include mobile {margin:0; bottom: 10%; right: 10%;}
            }

        }
    }
}




/*  =promoBanners
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.promoBanners {
    @include mobile {
        display: none;
        &.salonGeniusBanner {
            display: block;
        }
    }
    position: relative;
    .promo {
        position: relative;
        margin-bottom: 20px;
        img {
            width: 100%;
            height: auto;
        }
        .button {
            position: absolute;
        }
    }
}




/*  =home slider
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.homeBannerSlider {
    position: relative;
    width: 940px;
    height: 560px;
    margin-bottom: 40px;
    // left: -1px;
    // padding-right: 1px;
    @include mobile {
        width: 100%;height: auto;margin-bottom: 0px;
        .button {
            min-width: 110px !important;
            height: 25px !important;
            font-size: 1.3rem;
            color: #bbb;
            padding: 0;
            span {
                line-height: 14px;
                margin: 5px;
                color: #bbb;
                padding: 0 5px;
            }
        }
    }

    .slick-prev {
        @include hideText;
        background: url("../img/Sprite_DT.png") no-repeat 0 0;
        background-position: 4px -477px;
        height: 45px;
        width: 29px;
        border: 0;
        display: block;
        position: absolute;
        left: 16px;
        top: 46%;
        z-index: 10;
        cursor: pointer;
        opacity: 0.5;

        @include mobile {
            background: url("../img/Sprite_SP.png");
            background-position: -47px -35px;
            width: 49px;
            height: 60px;
            left: -22px;
            top: 41%;
        }
        &:hover {
            background: url("../img/Sprite_DT.png") no-repeat 0 0;
            background-position: 4px -477px;
            opacity: 0.9;
            @include mobile {
                background: url("../img/Sprite_SP.png");
                background-position: -47px -35px;
            }
        }
    }
    .slick-next {
        @include hideText;
        background: url("../img/Sprite_DT.png") no-repeat 0 0;
        background-position: 4px -518px;
        height: 45px;
        width: 29px;
        border: 0;
        display: block;
        position: absolute;
        right: 16px;
        top: 46%;
        z-index: 10;
        cursor: pointer;
        opacity: 0.5;

        @include mobile {
            background: url("../img/Sprite_SP.png");
            background-position: -92px -91px;
            width: 49px;
            height: 60px;
            right: -22px;
            top: 41%;
        }
        &:hover {
            background: url("../img/Sprite_DT.png") no-repeat 0 0;
            background-position: 4px -518px;
            opacity: 0.9;
            @include mobile {
                background: url("../img/Sprite_SP.png");
                background-position: -92px -91px;
            }
        }
    }
    .slick-list {
        .slick-slide {
            position: relative;
            .button {
                position: absolute;
                z-index: 10;
                font-size: 1.3rem;
                color: #bbb;
                // &.verticallyCentered {
                //     top: 50%;
                //     left: 50%;
                //     transform: translateY(-50%);
                // }
                // &.horizontallyCentered {
                //     // top: 50%;
                //     right: inherit !important;
                //     left: 50%;
                //     transform: translateX(-50%);
                // }
                span {
                    padding: 0 8px;
                    color: #bbb;
                }
            }
            @include mobile {
                //height: 265px;
                img {
                    //height: 265px;
                    // max-width: 100%;
                    width: 100%;
                }
                .button {
                    // font-size: 1.3rem;
                    height: auto !important;
                    display: block;
                    // font-family: arial !important;
                    span {
                        // margin: 6px;
                        margin: 1px;
                        padding: 6px;
                        display: block;
                        line-height: 1.3rem;
                    }
                }
            }
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 20px;
        left: 50%;
        // width: 65px;
        width: 85px;
        margin-left: -35px;
        // @include mobile {width: 75px;bottom:10px;}
        @include mobile {
            width: 100px;
            bottom:10px;
        }

        li {
            display: inline-block;
            margin-right: 5px;

            button {
                @include hideText;
                display: block;
                border: 0;
                height: 17px;
                width: 16px;
                cursor: pointer;
                background: url("../img/Sprite_DT.png");
                background-position: 4px -143px;
                @include mobile {
                    background: url("../img/Sprite_SP.png");
                    background-position: -720px -499px;
                    width: 19px;
                    height: 19px;
                }
            }
        }
        .slick-active {
            button {
                background: url("../img/Sprite_DT.png");
                background-position: 4px -123px;
                @include mobile {
                    background: url("../img/Sprite_SP.png");
                    background-position: -720px -528px;
                    width: 19px;
                    height: 19px;
                }
            }
        }
    }
}




/*  =special links
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.specialLinks {
    .specialLinks__item {
        position: relative;

        a {
            display: block;
            font-size: 1.35rem;
            font-weight: 300;
            line-height: 60px;
            border: 1px solid #444;
            border-bottom: 0;
            padding-left: 10px;
        }
        &:last-child a {
            border-bottom: 1px solid #444;
        }
        &:after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 0 6px 9px;
            border-color: transparent transparent transparent #666;
            position: absolute;
            right: 15px;
            bottom: 23px;
        }
    }
}




/*  =short menu
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.shortMenu {
    @include mobile {
        position: absolute;
        right: 0;
        top: 0;
        height: 65px;
        line-height: 65px;

        .searchButton {
            width: 35px;
            height: 35px;
            display: inline-block;
            margin-right: 13px;
            background: url("../img/SmartphoneSearchIcon.png") no-repeat center center;
            background-size: 100%;
            vertical-align: middle;
            @include hideText;

            &.active {
                background: url("../img/SearchIconClose.png") no-repeat center center;
                background-size: 100%;
            }
        }
        .menuButton {
            width: 35px;
            height: 35px;
            display: inline-block;
            background: url("../img/NavigationIcon.png") no-repeat center center;
            background-size: 100%;
            vertical-align: middle;
            margin-right: 5px;
            @include hideText;

            &.active {
                // NIE DZIAŁA POPRAWIC TO
                width: 14px;
                height: 14px;
                background: url("../img/NavigationIconClose.png") no-repeat center center;
                background-size: normal;
            }
        }
    }
}


.socialMediaLinks {
    margin-top: 40px;
    padding-bottom: 12px;
    overflow: hidden;

    .followUs {
        float: left;

        .title {
            font-size: 1.1rem;
            color: #fff;
            font-weight: 500;
        }
        ul li {
            display: block;
            float: left;
            line-height: 33px;
            margin-right: 10px;
        }
        ul li a.symbol {
            font-size: 3rem;
            font-weight: normal;
            
            &.xsymbol {
                font-size: 2rem;
            }
        }
    }
}
