.register__sidebar {
    float: right;
    width: 300px;
    color: #bbb;
    @include mobile {
        display: none;
    }

    h3 {
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 300;
        margin-bottom: 10px;
    }
    span {
        font-size: 1.2rem;
        display: block;
        margin-bottom: 10px;
    }
    .section {
        margin-bottom: 40px;
    }
    .faq_button {
        width: 130px;
        line-height: 25px;
        border: 1px solid #666;
        text-align: center;
        display: block;
        font-size: 1.2rem;
        color: #bbb;
        margin-top: 15px;
    }
    .socialMediaLinks {
        margin-top: 0;
    }
}
.passwordInfoText {
    font-size: 1.2rem;
    padding-top: 5px;
    display: inline-block;
}
.signIn__page {
    .formContainer .formColShort {
        width: 60%;
        @include mobile {
            width: 100%;
        }
    }
}
.resetPassword__page {
    .formContainer .formColShort {
        width: 47%;
        margin-right: 3%;
        @include mobile {
            width: 100%;
        }
    }
    @include desktop {
        .sortSelectWrap {
            width: 47%;
            .sortSelect {
                width: 48%;
            }
        }
        .register__headline {
            margin-bottom: 30px;
        }
    }
}

.editProfile__page {
    position: relative;

    .formContainer .formColShort {
        width: 47%;
        margin-right: 3%;
        @include mobile {
            width: 100%;
        }
    }
    @include mobile {
        .register__headline {
            margin-bottom: 15px;
        }
    }
    @include desktop {
        .sortSelectWrap {
            width: 47%;
            .sortSelect {
                width: 48%;
            }
        }
        .register__headline {
            margin-bottom: 30px;
        }
    }
}
input[disabled] {
    cursor: not-allowed;
}
.profile__page {
    .formContainer .formColShort {
        width: 50%;
        @include mobile {
            width: 100%;
        }
    }
    .updateProfile {
        color: #bbb;
        font-size: 1.2rem;
        float: right;
        padding-top: 10px;
        text-decoration: underline;

        @include mobile {
            bottom: auto;
            top: 0;
        }

        &:hover {
            color: #bbb;
        }
    }
    .formColShort {
        padding-bottom: 25px;
        .profileValue {
            font-size: 1.2rem;
            color: #bbb;
        }
        .label {
            border-bottom: 1px dashed #666;
            margin-bottom: 15px;
            font-size: 1.2rem;
        }
    }
    .formContainer .checkBox label {
        color: #bbb;
    }
    .formContainer .checkBox #specialOffers {
        cursor: not-allowed;
    }
    .register__headline {
        h3 {
            float: left;
            margin: 10px 0 30px 0;
            @include mobile {
                display: block !important;
                font-size: 1.3rem;
            }
        }
    }
}

.resetPassword_button {
    @include desktop {
        color: #b30022;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 1.5rem;
        float: right;
        padding-top: 10px;
    }
    @include mobile {
        position: absolute;
        right: 2%;
        top: 5px;
        text-transform: uppercase;
        color: #bbb;
    }
}

.register {
    width: 600px;
    float: left;
	background: url("../i/bg/Spectra.png") no-repeat 0 0;
	//padding: 0 20px 40px;
    @include desktop {
        margin-bottom: 200px;
    }
    @include mobile {
        width: 100%;
        padding: 0 10px 20px 10px;
    }

    h2 {
        font-size: 3.2rem;
        font-weight: 100;
        @include mobile {
            font-size: 1.6rem;
        }
    }
    h3 {
        color: #bbb;
        font-size: 16px;
        text-transform: capitalize;
        font-weight: 300;
    }
    .createButton {
        border: 1px solid #b30022;
        color: #b30022;
        padding: 3px 25px;
        background-color: transparent;
        margin: 40px 0 20px 0;
        cursor: pointer;
        min-width: 120px;

        @include mobile {
            margin: 0 0 20px 0;
        }
    }
    .signInLine {
        color: #fff;
        font-size: 1.2rem;
        @include mobile {
            font-size: 1.6rem;
        }
        .signInLink {
            text-decoration: underline;
            color: #bbb;
            @include mobile {
                color: #fff;
            }
        }
    }
    .register__headline {
        color: #bbb;
        margin-top: 30px;
        border-top: 1px solid #666;

        @include mobile {
            margin-top: 10px;
            padding-top: 0;
            border-color: #444;
        }
        p {
            margin: 10px 0 30px 0;
            font-size: 1.2rem;
            @include mobile {
                color: #bbb;
                margin-bottom: 10px;
            }
        }
        h3 {
            @include mobile {
                display: none;
            }
        }
    }
    .formContainer {
		width: 600px;
	}
    .header {
        width: 600px;
        @include clearfix;
        position: relative;

        @include mobile {
            width: 100%;
        }
    }
    .label {
        font-size: 1.8rem;
        padding-bottom: 5px;
    }
    .profileValue {
        font-size: 1.4rem;
    }
	.error {
        font-size: 1.2rem;
        color: #b30022;
	}
    & > .error {
	    padding-bottom: 20px;
    }
}

@include mobile {
	.register {
		background: none;
		.formContainer { width: 100%; }
	}
}

.underagePopup, .logOutPopup {
    width: 500px;
    background: #fff;
    opacity: 1;

    @include mobile {
        width: 80%;
    }

    .popupContent {
        padding: 65px;
    }
    .message {
        font-size: 1.8rem;

        @include mobile {
            font-size: 1.4rem;
        }

        a {
            text-decoration: underline;
        }
    }
    .popupTitle {
        font-size: 1.6rem;
        font-weight: bold;
        padding: 0 0 20px 0;
    }
}
.logOutPopup {
    .button {
        margin-left: 10px;

        @include mobile {
            display: block;
            margin: 10px 0;
        }
    }
}
