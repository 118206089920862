﻿.button {
    background-color: #000;
    display: inline-block;
    z-index: 10;
    height: 40px;
    min-width: 160px;
    font-size: 1.2rem;
    cursor: pointer;

    span {
        font-family: 'GothamLight';
        font-weight: 400;
        border: 1px solid #666;
        color: #666;
        display: block;
        text-align: center;
        margin: 5px 10px;
        line-height: 27px;
    }
    &.normal {
        border: 1px solid #666;
        height: auto;
        min-width: auto;
        padding: 5px 30px;
        background: none;
    }
    &.gray {
        background: #1a1a1a;
        margin: 0 auto;
        text-align: center;
        span {
            border: 1px solid #666;
            color: #666;
        }
    }
    &.bright {
        background: #0d0d0d;
        span {
            border: 1px solid #666;
        }
    }
    &.red {
        background-color: #1a1a1a;
        border: 1px solid #b30022;
        color: #b30022;
    }

}
