﻿
/*	=ARTICLE PAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/

.article {
    width: 74%;
    float: left;
    color: #b8b8b8;
    font-size: 1.6rem;
    padding-bottom: 40px;
    @include mobile {padding:0 2%;width: 100%;font-size: 1.2rem;}

    .article__title {
        margin-top:20px;
        font-size: 3.4rem;
        color: #fff;
        font-weight: 300;
        text-transform: uppercase;
        @include mobile {
            font-size: 1.8rem;
            margin-top: 0;
            float: left;
        }
    }
    .article__short {
        font-size: 2.4rem;
        font-weight: 300;
        margin-top: 40px;
        margin-bottom: 20px;
        @include mobile {
            font-size: 1.2rem;
            border-bottom: 1px dashed #666;
            padding-bottom: 10px;
            margin: 20px 0 10px 0;
        }
    }
    img {
        max-width: 100%;
    }
    strong {
        font-weight: 500;
    }
    .article__header {
        position: relative;
        padding-right: 6%;
        @include clearfix;

        .sendEmail {
            display: none;
            @include mobile {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                @include sprite($-article-listing-email);
            }
        }
    }
    .article__headline {
        border-top: 1px dashed #666;
        padding-top: 20px;
        margin: 40px 0 30px 0;
        overflow: hidden;
        @include mobile {
            border-top: 0;
            border-bottom: 1px dashed #666;
            padding-bottom: 20px;
            padding-top: 0;
            margin: 20px 0 20px 0;
        }

        .headline__recomend {
            float: left;
            font-size: 1.1rem;
            @include mobile {}
            .howMany {
                padding-right: 20px;
            }
            a.like {
                cursor: pointer;
                text-decoration: underline;
                @include mobile {
                    padding: 0;
                    display: block;
                    width: 100%;
                }
            }
        }
        .headline__share {
            float: right;
            @include mobile {display: none;}
            .socialMediaLinks {
                margin: 0;
                padding: 0;
                li {
                    margin-right: 0;
                    line-height: normal;
                    &.title {
                        font-weight: 300;
                        color:#bbb;
                    }
                    a.symbol {
                        line-height: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        a {color:#bbb;}
    }
    .article__cms__content {
        @include mobile {
            margin-bottom: 20px;
        }
    }
}

.video__thumbnail {
    margin-bottom: 10px;
    position: relative;
    cursor: pointer;
    width: 560px;
    height: 315px;
    @include mobile {
        margin-bottom: 0;
        width: 100%;
        height: auto;
    }

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        @include sprite($-video-thumbnail);
    }
    img {
        max-width: 100%;
        @include mobile {
            width: 100%;
        }
    }
}

.article__sidebar {
    width: 220px;
    float: right;
    position: relative;
    margin-top: 20px;
    @include mobile {padding:0 2%;width: 100%;}

    .article__menu {
        border-top: 1px solid #666;
        border-bottom: 1px dashed #666;
        margin-bottom: 60px;
        @include mobile {
            border: 0;
            display: block;
            overflow: hidden;
            margin-top: 20px;
            margin-bottom: 40px;
            padding: 25px 0;
            border-bottom: 1px dashed #666;
            border-top: 1px dashed #666;
        }

        .menu__title {
            color: #b8b8b8;
            display: block;
            font-size: 1.2rem;
            padding-top: 10px;
            margin-bottom: 20px;
            @include mobile {
                // float: left;
                font-size: 1.4rem;
                padding-top: 0;
                vertical-align: middle;
                margin: 0 auto;
                text-align: center;
            }
        }
        .menu__list {
            margin: 0 auto;
            text-align: center;
            display: block;
            text-align: left;
            // float: right;
            @include mobile {
                margin: 20px auto 0 auto;
                text-align: center;
                // display: inline-block;
                width: 70%;
                float: none;
            }
            li.menu__item {
                display: block;
                font-size: 1.5rem;
                border-top: 1px dashed #666;
                padding: 10px 0;
                text-transform: uppercase;
                @include mobile {
                    border: 1px solid #b8b8b8;
                    display: inline-block;
                    font-size: 1.4rem;
                    padding: 10px;
                    margin: 0 7px;
                    &:last-of-type {
                    }
                }

                a {
                    color: #666;
                    @include mobile {color: #b8b8b8;}
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
    .printLink {
        position: absolute;
        top: -56px;
        right: 0;
        color: #bbb;
        font-size: 1.1rem;
        cursor: pointer;

        .printIcon {
            display: inline-block;
            float: right;
            margin-left: 5px;
            @include sprite($print);
        }
    }
}

.article__banner {
    @include mobile {
        display: none;
    }
    .banner {
        position: relative;
    }
    .button {
        position: absolute;
        span {
            color: #bbb;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.article__cms__content {
    ul {
        margin: 20px 0;
    }
    p {
        margin-bottom: 10px;
    }
    li {
        font-weight: 400;
        line-height: 22px;
        list-style-type: disc;
        margin-left: 17px;
        padding-bottom: 15px;
    }
}

#the-salon-genius-view {
    .article__header {
        @include mobile {margin-top: 20px;}
    }
    .article__short {
        @include mobile {margin-top: 10px;}
    }
}
