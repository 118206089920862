@mixin hideText() {
    text-indent: -9999em;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
    cursor: pointer;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
        content: ".";
        display: block;
        font-size: 0;
        height: 0;
        line-height: 0;
        visibility: hidden;
    }
}

@mixin arrowRightMenu() {
    display: inline-block;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 9px;
    border-color: transparent transparent transparent #666;
    position: absolute;
    right: 10px;
    bottom: 23px;
}
