

body {
    @include mobile {
        &.subCollection {
            .pageBanner {display: none;}
            .sidebar .categoryProducts {display: none;}
        }
        &.collection {
            // .sidebar .categoryProducts li.accordion__item:first-child {
            //     display: none;
            // }
            .accordion.categoryProducts ul.accordion__list > li.accordion__item:after {
                @include arrowRightMenu;
            }
        }
        &.products .main {
            display: none;
        }
    }
}


.blockStyle {
    border-top: 1px dotted #666;
    color: #bbb;
    @include clearfix;

    .block__title {
        margin: 15px 0 42px 0;
        font-size: 1.1rem;
        text-transform: uppercase;
    }
}

.zoomContainer {
    z-index: 10;
    text-align: center;
}

.product__page {
    @include clearfix;
    color: #bbb;
    margin-top: 30px;
    padding-bottom: 20px;

    .product__title {
        font-size: 2rem;
        padding: 0 2%;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .product__visual {
        width: 40%;
        float: left;
        margin-right: 10%;
        position: relative;

        @include mobile {
            width: 98%;
            margin: 0 1%;
        }

        .product__carousel {
            width: 100%;
            text-align: center;
            position: relative;

            .goLeft, .goRight {
                display: none;
            }
            .zoomIcon {
                position: absolute;
                right: 50px;
                bottom: 90px;
                @include sprite($magnifier);
            }
            ul.visualList {
                width: 300px;
                height: 380px;
                margin: 0 auto;

                li {
                    width: inherit;
                    height: inherit;
                    @include mobile {
                        pointer-events: none;
                    }
                }
                img {
                    width: auto;
                    max-width: 100%;
                    @include mobile {
                        pointer-events: none;
                    }
                }
            }
            ul.thumbnailsList li {
                float: none;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }

    }
    .product__information {
        width: 50%;
        float: right;
        position: relative;
        @include mobile {
            width: 96%;
            margin: 0 2%;
        }

        .product__rating {
            margin: 10px 0;
            cursor: pointer;
            @include mobile {display: none;}
        }
        .product__share {
            position: absolute;
            top: -85px;
            right: 0;
            @include clearfix;
            &.socialMediaLinks {
                margin: 0;
                li, a {
                    color: #bbb;
                }
            }
            @include mobile {display: none;}
        }
        .product__title {
            font-size: 3.4rem;
            font-weight: 300;
            color: #fff;
            line-height: 45px;
            text-transform: uppercase;
            @include mobile {
                font-size: 2em;
            }
        }
        .product__description {
            font-size: 1.6rem;
            font-weight: 400;
            padding-bottom: 50px;
            margin-bottom: 10px;
            border-bottom: 1px dashed #666;
            ul {
                padding-top: 10px;
            }
            ul li {
                font-weight: 400;
                line-height: 22px;
                list-style-type: disc;
                margin-left: 17px;
            }
            @include mobile {
                border: 0;
                padding-bottom: 0;
                font-size: 1.4rem;
            }
        }
        .product__review_links {
            padding-bottom: 10px;
            @include mobile {display: none;}

            .readAllReview {
                text-decoration: underline;
                color: #b9b9b9;
                font-size: 1.1rem;
                border-right: 1px solid #666;
                padding-right: 8px;
                margin-right: 3px;
                cursor: pointer;
            }
            .writeReview {
                font-size: 1.4rem;
                color: #b30022;
                margin-left: 6px;
            }
        }
        .product__buyNow {
            @include mobile {display: none;}
            .button {
                position: static;
            }
        }
    }
}


.product__visual {
    width: 225px;
    margin-right: 20px;
    float: left;

    .info {
        margin: 15px 0 20px 0;
        display: inline-block;
        height: 152px;
        overflow: auto;
    }
    .visualList img {
        width: 225px;
        background-color: "red";
    }
    .product__carousel {
        width: inherit;
        overflow: hidden;
        position: relative;
        ul.visualList li {
            display: none;
            &.active {display: block;}
        }
        .thumbnails {
            margin-top: 25px;
            @include mobile {display: none;}
        }
        ul.thumbnailsList li {
            float: left;
            cursor: pointer;
            display: inline-block;
            border: 1px solid transparent;

            img {width: 46px; height: 57px;display: block;}
            &.active {
                border: 1px solid #bbb;
            }
        }
    }
    a.goLeft {
        position: absolute;
        left: 0;
        top: 100px;
        display: block;
        @include sprite($previous);
        @include hideText;
    }
    a.goRight {
        position: absolute;
        right: 0;
        top: 100px;
        display: block;
        @include sprite($-next);
        @include hideText;
    }
}

.product__variants {
    @include clearfix;
    @include mobile {
        @include mobile {display: none;}
        &.mobileOnly {
            display: block;
            border-top: 1px dashed #666;
            padding: 15px 2% 0 2%;
        }
    }

    .variant__title {
        color: #c1032c;
        font-size: 1.5rem;
        text-transform: uppercase;
        small {
            @include mobile {
                font-size: 1.1rem;
                color: #bbb;
            }
        }
    }
    ul.variant__categoryList {
        display: block;
        margin: 10px 0 20px 0;
        cursor: pointer;
        @include clearfix;
        @include mobile {
            margin-bottom: 10px;
            margin-top: 15px;
        }

        li {
            float: left;
            margin-right: 20px;
            font-size: 1.2rem;
            text-transform: uppercase;
            &:first-child {
                text-transform: none;
            }
            @include mobile {
                border: 1px solid #666;
                margin-right: 0;

                &:first-child {
                    border-right: 0;
                }
            }
            a {
                color: #bbb;
                @include mobile {
                    display: block;
                    line-height: 30px;
                    padding: 0 28px;
                    text-align: center;
                }
            }
            a.active {
                color:#fff;
                @include mobile {
                    background: #444;
                    color: #1a1a1a;
                }
            }
        }
    }
    .variant__elements {
        margin-bottom: 20px;
        @include clearfix;
        @include mobile {
            margin-bottom: 0;
            > div {
                margin-bottom: 20px;
            }
        }
        img {
            cursor: pointer;
            &.active {
                border: 2px solid #fff;
            }
        }
        ul.inline__list {
            display: block;
            width: 100%;
            @include clearfix;

            li {

                float: left;
                a {
                    display: block;
                    width: 48px;
                    height: 58px;
                    -webkit-box-shadow:inset 0px 0px 0px 10px #f00;
                    -moz-box-shadow:inset 0px 0px 0px 10px #f00;
                    box-shadow:inset 0px 0px 0px 10px #f00;
                    z-index: 10;
                    @include mobile {
                        width: 75px;
                        height: 95px;
                    }
                }
                img {
                    display: block;
                    width: 48px;
                    height: 58px;
                    @include mobile {
                        width: 75px;
                        height: 95px;
                    }
                }
            }
        }
        ul.grid__list {
            li {
                float: left;
                height: 58px;
                width: 50%;
                display: block;
                margin-bottom: 10px;
                @include mobile {
                    width: 100%;
                    height: 111px;
                    margin-bottom: 5px;
                    float: none;
                }

                .name {
                    text-transform: uppercase;
                    @include mobile {
                        padding-top: 10px;
                        color: #bbb;
                        display: inline-block;
                    }
                }
                .shade {
                    width: 48px;
                    float: left;
                    margin-right: 10px;
                    @include mobile {
                        width: 89px;
                        height: 111px;
                    }
                }
            }
        }
        .variantScroll {
            @include desktop {
                height: 200px;
            }
            margin-bottom: 0;
            overflow: hidden;
            position: relative;
            @include mobile {
                max-height: 200px;
            }
        }
        .sortSelect {
            width: 225px;
            height: 25px;
            margin-bottom: 20px;
            select {
                float: none;
                -moz-appearance: none; -webkit-appearance: none; -ms-appearance: none; appearance: none;
            }
        }
    }
}

.product__details {
    padding-bottom: 30px;
    @include mobile {display: none;}

    .column {
        font-size: 1.6rem;
        strong {
            font-weight: 500;
        }
        h1,h2,h3,h4,h5,h6 {
            font-weight: 500;
            margin-bottom: 5px;
            //line-height: 32px;
        }
        p {
            font-weight: 400;
            line-height: 22px;
            padding-bottom: 20px;
        }
        li {
            font-weight: 400;
            line-height: 22px;
            list-style-type: disc;
            margin-left: 17px;
            padding-bottom: 15px;
        }
    }
    .column1 {
        width: 49%;
        margin-right: 2%;
        float: left;
    }
    .column2 {
        width: 49%;
        float: left;
    }
}

.product__recommended {
    @include clearfix;
    @include desktop {
        width: 100%;
        clear: both;
        position: relative;
        z-index: 0;
    }
    @include mobile {
        margin: 0 2%;
        border-top: 0;
        .block__title {
            color: #fff;
            font-size: 1.8rem;
            margin-bottom: 30px;
        }
    }
    .product__recommended_list {
        .product__tile {
            width: 219px;
        }
        .product__tile:nth-child(3n+3) {
            margin-right: 20px;
            @include mobile {margin-right: 1%;}
        }
        .product__tile:nth-child(4n+4) {
            margin-right: 0;
            @include mobile {margin-right: 1%;}
        }
        .product__packshot {
            width: auto;
            img {
                max-width: 100%;
            }
        }

        @include mobile {
            .product__tile {
                width: 49.5%;
                margin-right: 1%;
                &:nth-child(2n+2) {
                    margin-right: 0;
                }
                .product__shop {
                    height: 170px;
                }
            }
            .product__price {
                color: #fff;
                font-size: 2rem;
                small {
                    font-size: 2rem;
                }
            }
        }
    }
    // wariant tylko na stronie article
    &.article__releated {
        @include desktop {
            width: 74%;
            .product__tile {
                margin-right: 19px;
            }
            .product__tile:nth-child(3n+3) {
                margin-right: 0;
            }
        }
    }
}


.product__ratings {
    @include mobile {display: none;}
}

.product__accordion {
   //border-bottom: 1px solid #666;

    .accordion__item {
        border-bottom: 0 !important;
        .accordion__title {
            border-top: 1px solid #666;
            border-bottom: 1px solid #666;
            line-height: 40px;
            font-size: 1.2rem;
            padding-left: 10px;
            @include mobile {border-top: 0;}
        }
    }
}

.product__recommended.recentlyViewDynamic {
    @include mobile {display: none;}
    .product__recommended_list {
        .product__tile {
            width: 170px;
            &:nth-child(5) {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
}
