﻿@import "partials/vars";

// @include sprite($arrow_down_select);

$red: #b30022;

#compare-tray {
  background: $red;
  // display: inline-block;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: 0 auto;
  min-height: 97px;
  // padding-top: 32px;
  padding-top: 20px;
  padding-bottom: 40px;
  left: 0;
  z-index: 999999;
  // transition: max-height 400ms ease-in-out;
  max-height: 500px;
  .compare-wrapper {
    width: 80%;
    max-width: 940px;
    margin: 0 auto;
    // overflow: auto;
    position: relative;
    ul {
      .row {
        &:before{
          display: table;
          content: "";
          line-height: 0;
        }
      }
      li {
        height: 263px;
        display: inline-block;
        float: left;
        color: #fff;
        font-size: 12px;
        padding: 0 5px 0 0;
        text-align: left;
        width: 15.667%;
        min-width: 137px;
        margin: 19px 4px 65px;
        min-height: 95px;
        position: relative;
        .compare-tag {
          color: #fff;
          font-size: 11px;
          font-weight: 400;
          text-transform: uppercase;
          padding-bottom: 10px;
          margin-bottom: 10px;
          display: block;
        }
        .compare-tag-min {
          display: none;
        }
        span {
          &.btn {
            border: 1px solid #bbb;
            border-radius: 0;
            box-shadow: none;
            color: #bbb;
            font-size: 12px;
            font-weight: 400;
            text-shadow: none;
            padding: 10px;
            display: inline-block;
            opacity: .65;
            cursor: not-allowed;
            &.active {
              opacity: 1;
              cursor: pointer;
            }
          }
        }
        a {
          &.list-close {
            position: absolute;
            right: 3px;
            top: -17px;
            display: block !important;
            @include sprite($compareclose);
            cursor: pointer;
          }
        }
        .compare-item {
          h4 {
            background: none;
            border-bottom: 1px dotted #bbb;
            color: #fff;
            float: left;
            font-weight: normal;
            line-height: 15px;
            margin: 0;
            padding: 0 0 70px;
            position: static;
            text-transform: uppercase;
            width: 98%;
            position: relative;
            a {
              position: absolute;
              right: 9px;
              top: -8px;
              left: 0;
              width: 96%;
              font-size: 11px;
            }
          }
          .desc {
            img {
              width: 80%;
              max-width: 100%;
              height: auto;
              display: inline;
              float: left;
              padding: 0;
              text-align: right;
              width: 98%;
              margin-top: 44px;
            }
          }
        }
        .rating-wrapper {
          .rating {
            height: 12px;
            position: relative;
            float: left;
            margin: 0 5px 0 0;
            width: 100px;
          }
          .reviewtext {
            float: left;
            margin: 1px 0 0;
            display: block;
          }
        }
      }
    }
  }
  .compare-minimize {
    //   width: 12px; height: 8px;
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   @include sprite($compare-down);
    //   display: inline-block;
    //   cursor: pointer;
    //   transform: rotate(0deg);
    //   transition: transform 400ms linear;
        min-width: 100px; height: 30px;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        border: solid 1px white;
        cursor: pointer;
        &:before {
            content: "Hide";
            margin: 0 auto;
            width: inherit;
            display: block;
            text-align: center;
            line-height: 28px;
            font-size: 1.2em;
        }
  }
  .compare-close {
        @include sprite($compareclose);
        float: right;
        // margin-right: 25px;
        cursor: pointer;
        position: absolute;
        right: -40px;
        top: 10px;
  }
  transition: max-height 400ms ease-in-out;
  &.minimalized {
    overflow-y: hidden;
    max-height: 97px;
    transition: max-height 400ms ease-in-out;
    .compare-wrapper {
          .compare-tag, .btn {
            display: none;
            // position: absolute;
            // visibility: hidden;
            // opacity: 0;
            // transition: all .2s linear;
          }
          .compare-tag-min {
            display: block;
            cursor: pointer;
          }
      .compare-minimize {
        // transform: rotate(180deg);
        // transition: transform 400ms linear;
        &:before {
            content: "Compare";
        }
      }
    }
  }
}

.compare-popup {
  margin: 0 auto;
  top: -100px;
  display: table;;
  max-width: 940px;
  width: 90%;
  min-height: 157px;
  color: #fff;
  // position: absolute;
  @include mobile {
    top: 0;
    width: 100%;
    // position: static;
  }
  .closePopup {
      @include mobile {
        display: none;
      }
  }
  > div {
    padding: 40px;
    background: $red;
     @include mobile {
         background: #1a1a1a;
         padding: 15px;
     }

      img {
        // max-width: 100%;
        // height: auto;
        vertical-align: top;
        // display: block;
      }
      .modal-header {
        overflow: auto;
        span {
          float: right;
          display: block;
          @include sprite($compareclose);
          cursor: pointer;
          display: table-row;
        }
      }
      .modal-content {
        display: table;
        width: 100%;
        // table-layout: fixed;
        .cleanComparision {
            width: 80%;
            margin: 10px auto;
            padding: 5px 2%;
            text-align: center;
            display: block;
            font-size: 1.6rem;
            color: #bbb;
            font-weight: 300;
            background: #000;
            @include desktop {
              display: none;
            }
        }
        h4 {
            font-size: 1.7rem;
            text-transform: uppercase;
            padding-bottom: 20px;
            color: #bbb;
            font-weight: 300;
            @include desktop {
                display: none;
            }
        }
        a {
          font-size: 14px;
        }
        .modal-row {
          display: table-row;
          &.modal-data {
            height: 54px;
            text-align: center;
            text-transform: uppercase;
            vertical-align: middle;
          }
          .modal-left-cell {
            width: 15%;
            display: flex;
            float: left;
            vertical-align: top;
             @include mobile {
                 display: none;
             }
            .modal-cell {
              width: 100%;
            }
          }
          .modal-wrapper {
            width: 85%;
            display: table;
            table-layout: fixed;
            float: left;
            @include mobile {
                width: 100%;
                float: none;
            }
          }
          .modal-cell {
            display: table-cell;
            @include mobile {
                width: 50%;
                &:nth-child(2) {
                    .cell-data {
                        .mobileLabel {
                            visibility: hidden;
                            // opacity: 0;
                        }
                        .mobileInfo {
                        }
                    }
                }
            }
            .cell-top {
              margin-bottom: 20px;
              min-height: 450px;
              @include mobile {
                  margin-bottom: 10px;
                  min-height: initial;
                  background: #000;
                  padding: 2%;
                  width: 100%;
                  &:last-of-type {
                      margin-left: 2%;
                  }
              }
              .selectAnother {
                //   margin: 15px;
                  color: #bbb;
                  font-weight: 300;
                  cursor: pointer;
                  padding: 15px;
                  display: block;
                  margin: 0 auto;
                  text-align: center;
                  @include desktop {
                      display: none;
                  }
                  span {
                      padding: 5px;
                      width: 100%;
                  }
              }
              h3 {
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                margin: 0;
                text-transform: none;
                text-align: left;
              }
              .product-image {
                text-align: center;
                 @include mobile {
                     img {
                         max-width: 100%;
                         height: auto;
                     }
                 }
              }
              .modal-caption {
                overflow: visible;
                margin-left: 20%;
                min-height: 155px;
                // display: table-row;
                h3 {
                  font-size: 11px;
                  min-height: 75px;
                }
                .product-description {
                  // min-height: 90px;
                }
                .rating-wrapper {
                  // margin-left: -27px;
                  width: 100%;
                  .rating {
                    position: relative;
                    float: left;
                  }
                  .reviewtxt {
                    position: absolute;
                    top: 2px;
                    right: -20px;
                    span {
                      word-wrap: break-word;
                      display: inline;
                    }
                  }
                }
                .product-price {
                  width: 100%;
                  margin-bottom: 5px;
                  padding-bottom: 5px;
                  padding-top: 5px;
                  float: left;
                }
              }
              .modal-buy-now {
                cursor: pointer;
                letter-spacing: 2px;
                text-shadow: 0 0 0 transparent;
                text-transform: uppercase;
                margin-left: 20%;
                // display: table-cell;
                // margin-bottom: 20px;
                @include mobile {
                    display: none;
                }
                span {
                  border: 1px solid #fff;
                  // width: 60%;
                  font-size: 10px;
                  padding: 10px;
                  display: inline-block;
                  word-wrap: normal;
                  @include mobile {
                  text-align: center;
                    min-width: 60px;
                  }
                }
              }
            }
            .cell-data {
                @include mobile {
                    background: #000;
                    display: none;
                }
                .mobileLabel {
                    display: block;
                    position: relative;
                    width: 200%;
                    // width: 100%;
                    // top: -34px;
                    border-top: 1px dashed #666;
                    border-bottom: 1px dashed #666;
                    line-height: 1;
                    padding: 10px;
                    color: #bbb;
                    @include desktop {
                        display: none;
                    }
                }
                .mobileInfo {
                    position: relative;
                    min-height: 54px;
                }
                h3 {
                    @include desktop {
                        display: none;
                    }
                    @include mobile {
                        font-size: 1.1rem;
                        line-height: 1.2rem;
                        color: #bbb;
                        text-align: center;
                        padding: 20px;
                        height: 73px;
                        margin-bottom: 20px;
                        text-transform: uppercase;
                    }
                }
              > div {
                padding: 10px 0 30px;
                min-height: 54px;
                height: 97px;
                text-transform: uppercase;
                text-align: center;
                font-size: 11px;
                border-top: 1px dotted #fff;
                @include mobile {
                    // border-top: 1px dashed #666;
                    border-top: none;
                    min-height: initial;
                    height: initial;
                    // margin-top: 25px;
                }
                &:last-child {
                  border-bottom: 1px dotted #fff;
                  @include mobile {
                    //   border-top: 1px dashed #666;
                      border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .compareMobile {
      padding: 10px;
      h3 {
          font-size: 1.7rem;
          text-transform: uppercase;
          padding-bottom: 20px;
          color: #bbb;
          font-weight: 300;
      }
      .modal-content {
          overflow: auto;
          .modal-cell {
              width: 50%;
              display: inline-block;
              float: left;
          }
      }
  }


.comparePopupWrapper {
    position: relative;


    .buyNowPopup {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -200px;
        margin-top: -85px;
        height: 170px;
        padding: 30px;
        .closeBuyPopup {
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            @include sprite($compareclose);
        }
    }
}
