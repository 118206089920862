﻿/*	=ACCORDION
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/


.accordion {
    margin-bottom: 20px;

    // trik na ukrywanie pierwszego elementu na desktopie
    // na stronie /products
    &.categoryProducts {
        li.accordion__item  ul.accordion__sublist .accordion__item:first-child {
            @include desktop {display: none;}
        }

        li.accordion__item:after {
            //@include arrowRightMenu;
        }
    }

    .accordion__content {
        @include mobile {
            color: #bbb;
            border: 1px solid transparent;
            font-size: 1.1rem;
            transition: height 1s, border 1s;
            overflow: hidden;
            &.active {
                border-bottom: 1px solid #666;
            }
            .content {
                padding: 2%;
            }
            .componentContent {
                ul li {
                    list-style-type: none;
                    padding:0;
                    margin:0;
                }
                li {
                    background: #333;
                    height: 45px;
                }
            }
            &.hidden {
                border: 0;
                height: 0;
            }
            h4 {
                margin-bottom: 10px;
                font-weight: bold;
            }
            ul {
                margin-bottom: 10px;
            }
            ul li {
                font-weight: 400;
                line-height: 15px;
                list-style-type: disc;
                margin-left: 17px;
            }
            p {
                margin-bottom: 10px;
            }
        }
    }

    ul.accordion__list {
        @include mobile {border-top: 1px solid #666;}

        > li.accordion__item {

            a {
                color: #bbb;
                font-size: 1.4rem;
                text-transform: uppercase;
                display: inline-block;
                margin-bottom: 5px;
                line-height: 18px;
                @include mobile {
                    line-height: 51px;
                }
                &.active {
                    color: #fff;
                }
                &:hover {
                    @include desktop {color: #fff;}
                }
            }
            .accordion__title {
                cursor: pointer;
                @include mobile {
                    margin-bottom: 0;
                    padding-left: 10px;
                    line-height: 40px;
                    text-transform: uppercase;
                    color: #fff;
                    width: 100%;
                    position: relative;
                    display: block;
                    font-size: 1.3rem;
                    cursor: pointer;
                }
                &.active {
                    color: #fff !important;
                }
            }

            @include mobile {
                width: 100%;
                background: none;
                display: block;
                border-bottom: 1px solid #444;
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 13px;
                    height: 9px;
                    top: 15px;
                    right: 10px;
                    background: url("../img/DownNavigationIcon.png") no-repeat 0 0;
                    background-size: cover;
                }
                &.active:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 13px;
                    height: 9px;
                    top: 15px;
                    right: 10px;
                    background: url("../img/UpNavigationIcon.png") no-repeat 0 0;
                    background-size: cover;
                }
            }
            ul.accordion__sublist {
                @include mobile {
                    height: 0;
                    transition: height 1s;
                    overflow: hidden;
                }
                @include desktop {
                    // nadpisuje JSowy height:0 z mobajla
                    //height: auto !important;

                    &.hidden {
                        height: 0;
                        overflow: hidden;
                    }
                    &.visible {
                        height: 100%;
                    }
                }
                > li {
                    a {
                        color: silver;
                        font-size: 1.35rem;
                        padding: 0 0 5px 10px;
                        text-transform: none;
                        display: inline-block;
                        line-height: normal;
                        @include mobile {
                            padding: 0 0 0 25px;
                            margin-bottom: 0;
                            line-height: 50px;
                            text-transform: uppercase;
                            color: #fff;
                            font-size: 1.1rem;
                        }
                    }
                    @include mobile {
                        width: 100%;
                        background: #333;
                        display: block;
                        border-bottom: 1px solid #444;
                        position: relative;

                        &:last-child {border:0;}
                        &:after {
                            @include arrowRightMenu;
                        }
                    }
                }
            }
            &:last-child .accordion__title {border-top: 0;}
            //&:first-child .accordion__title {border-top: 1px solid #666;}
        }
    }
    .browsTitle {
        display: inline-block;
        color: #bbb;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 15px;
        @include mobile {display: none;}
    }
}
