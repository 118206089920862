.searchWrapper {
    width: 100%;
    @include clearfix;
    @include mobile {padding: 0 2%;}

    .searchHeader {
        h2 {
            font-size: 2.4rem;
            font-weight: 300;
            padding: 30px 0 47px 0;
            color: #bbb;
            @include mobile {
                color: #fff;
                text-transform: uppercase;
                padding: 5px 0 20px 0;
            }
            span {
                display: inline;
            }
        }
    }
    .searchContent {
        width: 75%;
        @include mobile {width: 100%;}

        .main {
            width: 100%;
            @include mobile {margin:0;}
        }
        .product__tile {
            @include desktop {width: 220px;}
            .product__title {
                color: #bbb;
            }
        }
        .headline {
            border-top: 0;
            border-bottom-style: dotted;
        }
        .headline__mobile {
            @include mobile {
                background: #0d0d0d;
                padding: 10px;
            }
        }
        .productsTab, .articlesTab {
            > span {
                margin: 27px 0;
                font-size: 1.3rem;
                font-weight: 500;
                display: block;
            }
        }


        // @include mobile {
        //     width: 100%;
        // }
        // // .tab {
        // //     margin-bottom: 0;
        // // }
        // .main {
        //     width: 100%;
        // }
        // .headline {
        //     border-top: none;
        //     border-bottom: 1px dotted #bbb;
        //     // width: 100%;
        //     @include mobile {
        //         border: none;
        //         // margin: 0 20px;
        //     }
        //     .headline__desktop {
        //         .sortSelect, .viewType {
        //             float: right;
        //         }
        //     }
        //     .headline__mobile {
        //         background: #0d0d0d;
        //         padding: 10px;
        //     }
        // }
        // .main__content {
        //     &.viewHorizontal {
        //         .product__tile {
        //             width: 100%;
        //         }
        //     }
        //     .product__tile {
        //         width: 220px;
        //         @include mobile {
        //             width: 100%;
        //         }
        //         .product__shop {
        //             .product__title {
        //                 color: #bbb;
        //                 font-weight: 300;
        //             }
        //         }
        //     }
        //     .contentWrap {
        //         position: absolute;
        //         bottom: 0;
        //     }
        // }
    }
}
