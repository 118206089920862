/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$-article-listing-email-name: 'Article_listing_email';
$-article-listing-email-x: 65px;
$-article-listing-email-y: 115px;
$-article-listing-email-offset-x: -65px;
$-article-listing-email-offset-y: -115px;
$-article-listing-email-width: 23px;
$-article-listing-email-height: 15px;
$-article-listing-email-total-width: 228px;
$-article-listing-email-total-height: 218px;
$-article-listing-email-image: '../img/sprites/sprite.png';
$-article-listing-email: (65px, 115px, -65px, -115px, 23px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'Article_listing_email', );
$-email-button-name: 'Email_Button';
$-email-button-x: 130px;
$-email-button-y: 120px;
$-email-button-offset-x: -130px;
$-email-button-offset-y: -120px;
$-email-button-width: 15px;
$-email-button-height: 10px;
$-email-button-total-width: 228px;
$-email-button-total-height: 218px;
$-email-button-image: '../img/sprites/sprite.png';
$-email-button: (130px, 120px, -130px, -120px, 15px, 10px, 228px, 218px, '../img/sprites/sprite.png', 'Email_Button', );
$-next-name: 'Next';
$-next-x: 199px;
$-next-y: 105px;
$-next-offset-x: -199px;
$-next-offset-y: -105px;
$-next-width: 13px;
$-next-height: 20px;
$-next-total-width: 228px;
$-next-total-height: 218px;
$-next-image: '../img/sprites/sprite.png';
$-next: (199px, 105px, -199px, -105px, 13px, 20px, 228px, 218px, '../img/sprites/sprite.png', 'Next', );
$-video-thumbnail-name: 'Video_Thumbnail';
$-video-thumbnail-x: 65px;
$-video-thumbnail-y: 65px;
$-video-thumbnail-offset-x: -65px;
$-video-thumbnail-offset-y: -65px;
$-video-thumbnail-width: 50px;
$-video-thumbnail-height: 50px;
$-video-thumbnail-total-width: 228px;
$-video-thumbnail-total-height: 218px;
$-video-thumbnail-image: '../img/sprites/sprite.png';
$-video-thumbnail: (65px, 65px, -65px, -65px, 50px, 50px, 228px, 218px, '../img/sprites/sprite.png', 'Video_Thumbnail', );
$ad-choice-icon-name: 'ad-choice-icon';
$ad-choice-icon-x: 0px;
$ad-choice-icon-y: 205px;
$ad-choice-icon-offset-x: 0px;
$ad-choice-icon-offset-y: -205px;
$ad-choice-icon-width: 12px;
$ad-choice-icon-height: 13px;
$ad-choice-icon-total-width: 228px;
$ad-choice-icon-total-height: 218px;
$ad-choice-icon-image: '../img/sprites/sprite.png';
$ad-choice-icon: (0px, 205px, 0px, -205px, 12px, 13px, 228px, 218px, '../img/sprites/sprite.png', 'ad-choice-icon', );
$arrow-down-name: 'arrow-down';
$arrow-down-x: 170px;
$arrow-down-y: 163px;
$arrow-down-offset-x: -170px;
$arrow-down-offset-y: -163px;
$arrow-down-width: 14px;
$arrow-down-height: 7px;
$arrow-down-total-width: 228px;
$arrow-down-total-height: 218px;
$arrow-down-image: '../img/sprites/sprite.png';
$arrow-down: (170px, 163px, -170px, -163px, 14px, 7px, 228px, 218px, '../img/sprites/sprite.png', 'arrow-down', );
$arrow-left-name: 'arrow-left';
$arrow-left-x: 219px;
$arrow-left-y: 15px;
$arrow-left-offset-x: -219px;
$arrow-left-offset-y: -15px;
$arrow-left-width: 9px;
$arrow-left-height: 15px;
$arrow-left-total-width: 228px;
$arrow-left-total-height: 218px;
$arrow-left-image: '../img/sprites/sprite.png';
$arrow-left: (219px, 15px, -219px, -15px, 9px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'arrow-left', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 219px;
$arrow-right-y: 0px;
$arrow-right-offset-x: -219px;
$arrow-right-offset-y: 0px;
$arrow-right-width: 9px;
$arrow-right-height: 15px;
$arrow-right-total-width: 228px;
$arrow-right-total-height: 218px;
$arrow-right-image: '../img/sprites/sprite.png';
$arrow-right: (219px, 0px, -219px, 0px, 9px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'arrow-right', );
$arrow-up-name: 'arrow-up';
$arrow-up-x: 145px;
$arrow-up-y: 120px;
$arrow-up-offset-x: -145px;
$arrow-up-offset-y: -120px;
$arrow-up-width: 15px;
$arrow-up-height: 9px;
$arrow-up-total-width: 228px;
$arrow-up-total-height: 218px;
$arrow-up-image: '../img/sprites/sprite.png';
$arrow-up: (145px, 120px, -145px, -120px, 15px, 9px, 228px, 218px, '../img/sprites/sprite.png', 'arrow-up', );
$arrowbox-next-name: 'arrowbox-next';
$arrowbox-next-x: 0px;
$arrowbox-next-y: 65px;
$arrowbox-next-offset-x: 0px;
$arrowbox-next-offset-y: -65px;
$arrowbox-next-width: 65px;
$arrowbox-next-height: 65px;
$arrowbox-next-total-width: 228px;
$arrowbox-next-total-height: 218px;
$arrowbox-next-image: '../img/sprites/sprite.png';
$arrowbox-next: (0px, 65px, 0px, -65px, 65px, 65px, 228px, 218px, '../img/sprites/sprite.png', 'arrowbox-next', );
$arrowbox-prev-name: 'arrowbox-prev';
$arrowbox-prev-x: 0px;
$arrowbox-prev-y: 0px;
$arrowbox-prev-offset-x: 0px;
$arrowbox-prev-offset-y: 0px;
$arrowbox-prev-width: 65px;
$arrowbox-prev-height: 65px;
$arrowbox-prev-total-width: 228px;
$arrowbox-prev-total-height: 218px;
$arrowbox-prev-image: '../img/sprites/sprite.png';
$arrowbox-prev: (0px, 0px, 0px, 0px, 65px, 65px, 228px, 218px, '../img/sprites/sprite.png', 'arrowbox-prev', );
$compare-down-on-name: 'compare-down-on';
$compare-down-on-x: 170px;
$compare-down-on-y: 24px;
$compare-down-on-offset-x: -170px;
$compare-down-on-offset-y: -24px;
$compare-down-on-width: 25px;
$compare-down-on-height: 25px;
$compare-down-on-total-width: 228px;
$compare-down-on-total-height: 218px;
$compare-down-on-image: '../img/sprites/sprite.png';
$compare-down-on: (170px, 24px, -170px, -24px, 25px, 25px, 228px, 218px, '../img/sprites/sprite.png', 'compare-down-on', );
$compare-down-name: 'compare-down';
$compare-down-x: 40px;
$compare-down-y: 162px;
$compare-down-offset-x: -40px;
$compare-down-offset-y: -162px;
$compare-down-width: 12px;
$compare-down-height: 8px;
$compare-down-total-width: 228px;
$compare-down-total-height: 218px;
$compare-down-image: '../img/sprites/sprite.png';
$compare-down: (40px, 162px, -40px, -162px, 12px, 8px, 228px, 218px, '../img/sprites/sprite.png', 'compare-down', );
$compareclose-name: 'compareclose';
$compareclose-x: 48px;
$compareclose-y: 193px;
$compareclose-offset-x: -48px;
$compareclose-offset-y: -193px;
$compareclose-width: 10px;
$compareclose-height: 10px;
$compareclose-total-width: 228px;
$compareclose-total-height: 218px;
$compareclose-image: '../img/sprites/sprite.png';
$compareclose: (48px, 193px, -48px, -193px, 10px, 10px, 228px, 218px, '../img/sprites/sprite.png', 'compareclose', );
$dropdown-arrow-name: 'dropdown-arrow';
$dropdown-arrow-x: 219px;
$dropdown-arrow-y: 48px;
$dropdown-arrow-offset-x: -219px;
$dropdown-arrow-offset-y: -48px;
$dropdown-arrow-width: 6px;
$dropdown-arrow-height: 5px;
$dropdown-arrow-total-width: 228px;
$dropdown-arrow-total-height: 218px;
$dropdown-arrow-image: '../img/sprites/sprite.png';
$dropdown-arrow: (219px, 48px, -219px, -48px, 6px, 5px, 228px, 218px, '../img/sprites/sprite.png', 'dropdown-arrow', );
$error-name: 'error';
$error-x: 170px;
$error-y: 0px;
$error-offset-x: -170px;
$error-offset-y: 0px;
$error-width: 29px;
$error-height: 24px;
$error-total-width: 228px;
$error-total-height: 218px;
$error-image: '../img/sprites/sprite.png';
$error: (170px, 0px, -170px, 0px, 29px, 24px, 228px, 218px, '../img/sprites/sprite.png', 'error', );
$footer-social-fb-m-name: 'footer-social-fb-m';
$footer-social-fb-m-x: 170px;
$footer-social-fb-m-y: 94px;
$footer-social-fb-m-offset-x: -170px;
$footer-social-fb-m-offset-y: -94px;
$footer-social-fb-m-width: 23px;
$footer-social-fb-m-height: 23px;
$footer-social-fb-m-total-width: 228px;
$footer-social-fb-m-total-height: 218px;
$footer-social-fb-m-image: '../img/sprites/sprite.png';
$footer-social-fb-m: (170px, 94px, -170px, -94px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-fb-m', );
$footer-social-fb-name: 'footer-social-fb';
$footer-social-fb-x: 170px;
$footer-social-fb-y: 71px;
$footer-social-fb-offset-x: -170px;
$footer-social-fb-offset-y: -71px;
$footer-social-fb-width: 24px;
$footer-social-fb-height: 23px;
$footer-social-fb-total-width: 228px;
$footer-social-fb-total-height: 218px;
$footer-social-fb-image: '../img/sprites/sprite.png';
$footer-social-fb: (170px, 71px, -170px, -71px, 24px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-fb', );
$footer-social-instagram-m-name: 'footer-social-instagram-m';
$footer-social-instagram-m-x: 46px;
$footer-social-instagram-m-y: 170px;
$footer-social-instagram-m-offset-x: -46px;
$footer-social-instagram-m-offset-y: -170px;
$footer-social-instagram-m-width: 23px;
$footer-social-instagram-m-height: 23px;
$footer-social-instagram-m-total-width: 228px;
$footer-social-instagram-m-total-height: 218px;
$footer-social-instagram-m-image: '../img/sprites/sprite.png';
$footer-social-instagram-m: (46px, 170px, -46px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-instagram-m', );
$footer-social-instagram-name: 'footer-social-instagram';
$footer-social-instagram-x: 23px;
$footer-social-instagram-y: 170px;
$footer-social-instagram-offset-x: -23px;
$footer-social-instagram-offset-y: -170px;
$footer-social-instagram-width: 23px;
$footer-social-instagram-height: 23px;
$footer-social-instagram-total-width: 228px;
$footer-social-instagram-total-height: 218px;
$footer-social-instagram-image: '../img/sprites/sprite.png';
$footer-social-instagram: (23px, 170px, -23px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-instagram', );
$footer-social-pinterest-m-name: 'footer-social-pinterest-m';
$footer-social-pinterest-m-x: 115px;
$footer-social-pinterest-m-y: 170px;
$footer-social-pinterest-m-offset-x: -115px;
$footer-social-pinterest-m-offset-y: -170px;
$footer-social-pinterest-m-width: 23px;
$footer-social-pinterest-m-height: 23px;
$footer-social-pinterest-m-total-width: 228px;
$footer-social-pinterest-m-total-height: 218px;
$footer-social-pinterest-m-image: '../img/sprites/sprite.png';
$footer-social-pinterest-m: (115px, 170px, -115px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-pinterest-m', );
$footer-social-pinterest-name: 'footer-social-pinterest';
$footer-social-pinterest-x: 69px;
$footer-social-pinterest-y: 170px;
$footer-social-pinterest-offset-x: -69px;
$footer-social-pinterest-offset-y: -170px;
$footer-social-pinterest-width: 23px;
$footer-social-pinterest-height: 23px;
$footer-social-pinterest-total-width: 228px;
$footer-social-pinterest-total-height: 218px;
$footer-social-pinterest-image: '../img/sprites/sprite.png';
$footer-social-pinterest: (69px, 170px, -69px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-pinterest', );
$footer-social-twitter-m-name: 'footer-social-twitter-m';
$footer-social-twitter-m-x: 92px;
$footer-social-twitter-m-y: 170px;
$footer-social-twitter-m-offset-x: -92px;
$footer-social-twitter-m-offset-y: -170px;
$footer-social-twitter-m-width: 23px;
$footer-social-twitter-m-height: 23px;
$footer-social-twitter-m-total-width: 228px;
$footer-social-twitter-m-total-height: 218px;
$footer-social-twitter-m-image: '../img/sprites/sprite.png';
$footer-social-twitter-m: (92px, 170px, -92px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-twitter-m', );
$footer-social-twitter-name: 'footer-social-twitter';
$footer-social-twitter-x: 170px;
$footer-social-twitter-y: 117px;
$footer-social-twitter-offset-x: -170px;
$footer-social-twitter-offset-y: -117px;
$footer-social-twitter-width: 23px;
$footer-social-twitter-height: 23px;
$footer-social-twitter-total-width: 228px;
$footer-social-twitter-total-height: 218px;
$footer-social-twitter-image: '../img/sprites/sprite.png';
$footer-social-twitter: (170px, 117px, -170px, -117px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-twitter', );
$footer-social-yt-m-name: 'footer-social-yt-m';
$footer-social-yt-m-x: 170px;
$footer-social-yt-m-y: 140px;
$footer-social-yt-m-offset-x: -170px;
$footer-social-yt-m-offset-y: -140px;
$footer-social-yt-m-width: 23px;
$footer-social-yt-m-height: 23px;
$footer-social-yt-m-total-width: 228px;
$footer-social-yt-m-total-height: 218px;
$footer-social-yt-m-image: '../img/sprites/sprite.png';
$footer-social-yt-m: (170px, 140px, -170px, -140px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-yt-m', );
$footer-social-yt-name: 'footer-social-yt';
$footer-social-yt-x: 0px;
$footer-social-yt-y: 170px;
$footer-social-yt-offset-x: 0px;
$footer-social-yt-offset-y: -170px;
$footer-social-yt-width: 23px;
$footer-social-yt-height: 23px;
$footer-social-yt-total-width: 228px;
$footer-social-yt-total-height: 218px;
$footer-social-yt-image: '../img/sprites/sprite.png';
$footer-social-yt: (0px, 170px, 0px, -170px, 23px, 23px, 228px, 218px, '../img/sprites/sprite.png', 'footer-social-yt', );
$grid-desktop-on-name: 'grid-desktop-on';
$grid-desktop-on-x: 199px;
$grid-desktop-on-y: 88px;
$grid-desktop-on-offset-x: -199px;
$grid-desktop-on-offset-y: -88px;
$grid-desktop-on-width: 17px;
$grid-desktop-on-height: 17px;
$grid-desktop-on-total-width: 228px;
$grid-desktop-on-total-height: 218px;
$grid-desktop-on-image: '../img/sprites/sprite.png';
$grid-desktop-on: (199px, 88px, -199px, -88px, 17px, 17px, 228px, 218px, '../img/sprites/sprite.png', 'grid-desktop-on', );
$grid-desktop-name: 'grid-desktop';
$grid-desktop-x: 199px;
$grid-desktop-y: 37px;
$grid-desktop-offset-x: -199px;
$grid-desktop-offset-y: -37px;
$grid-desktop-width: 17px;
$grid-desktop-height: 17px;
$grid-desktop-total-width: 228px;
$grid-desktop-total-height: 218px;
$grid-desktop-image: '../img/sprites/sprite.png';
$grid-desktop: (199px, 37px, -199px, -37px, 17px, 17px, 228px, 218px, '../img/sprites/sprite.png', 'grid-desktop', );
$grid-mobile-on-name: 'grid-mobile-on';
$grid-mobile-on-x: 40px;
$grid-mobile-on-y: 130px;
$grid-mobile-on-offset-x: -40px;
$grid-mobile-on-offset-y: -130px;
$grid-mobile-on-width: 32px;
$grid-mobile-on-height: 32px;
$grid-mobile-on-total-width: 228px;
$grid-mobile-on-total-height: 218px;
$grid-mobile-on-image: '../img/sprites/sprite.png';
$grid-mobile-on: (40px, 130px, -40px, -130px, 32px, 32px, 228px, 218px, '../img/sprites/sprite.png', 'grid-mobile-on', );
$grid-mobile-name: 'grid-mobile';
$grid-mobile-x: 104px;
$grid-mobile-y: 130px;
$grid-mobile-offset-x: -104px;
$grid-mobile-offset-y: -130px;
$grid-mobile-width: 32px;
$grid-mobile-height: 32px;
$grid-mobile-total-width: 228px;
$grid-mobile-total-height: 218px;
$grid-mobile-image: '../img/sprites/sprite.png';
$grid-mobile: (104px, 130px, -104px, -130px, 32px, 32px, 228px, 218px, '../img/sprites/sprite.png', 'grid-mobile', );
$grid-view-icon-on-name: 'grid-view-icon-on';
$grid-view-icon-on-x: 130px;
$grid-view-icon-on-y: 40px;
$grid-view-icon-on-offset-x: -130px;
$grid-view-icon-on-offset-y: -40px;
$grid-view-icon-on-width: 40px;
$grid-view-icon-on-height: 40px;
$grid-view-icon-on-total-width: 228px;
$grid-view-icon-on-total-height: 218px;
$grid-view-icon-on-image: '../img/sprites/sprite.png';
$grid-view-icon-on: (130px, 40px, -130px, -40px, 40px, 40px, 228px, 218px, '../img/sprites/sprite.png', 'grid-view-icon-on', );
$grid-view-icon-name: 'grid-view-icon';
$grid-view-icon-x: 130px;
$grid-view-icon-y: 0px;
$grid-view-icon-offset-x: -130px;
$grid-view-icon-offset-y: 0px;
$grid-view-icon-width: 40px;
$grid-view-icon-height: 40px;
$grid-view-icon-total-width: 228px;
$grid-view-icon-total-height: 218px;
$grid-view-icon-image: '../img/sprites/sprite.png';
$grid-view-icon: (130px, 0px, -130px, 0px, 40px, 40px, 228px, 218px, '../img/sprites/sprite.png', 'grid-view-icon', );
$icon-facet-on-name: 'icon-facet-on';
$icon-facet-on-x: 219px;
$icon-facet-on-y: 58px;
$icon-facet-on-offset-x: -219px;
$icon-facet-on-offset-y: -58px;
$icon-facet-on-width: 6px;
$icon-facet-on-height: 5px;
$icon-facet-on-total-width: 228px;
$icon-facet-on-total-height: 218px;
$icon-facet-on-image: '../img/sprites/sprite.png';
$icon-facet-on: (219px, 58px, -219px, -58px, 6px, 5px, 228px, 218px, '../img/sprites/sprite.png', 'icon-facet-on', );
$icon-facet-name: 'icon-facet';
$icon-facet-x: 219px;
$icon-facet-y: 53px;
$icon-facet-offset-x: -219px;
$icon-facet-offset-y: -53px;
$icon-facet-width: 6px;
$icon-facet-height: 5px;
$icon-facet-total-width: 228px;
$icon-facet-total-height: 218px;
$icon-facet-image: '../img/sprites/sprite.png';
$icon-facet: (219px, 53px, -219px, -53px, 6px, 5px, 228px, 218px, '../img/sprites/sprite.png', 'icon-facet', );
$list-desktop-on-name: 'list-desktop-on';
$list-desktop-on-x: 199px;
$list-desktop-on-y: 71px;
$list-desktop-on-offset-x: -199px;
$list-desktop-on-offset-y: -71px;
$list-desktop-on-width: 17px;
$list-desktop-on-height: 17px;
$list-desktop-on-total-width: 228px;
$list-desktop-on-total-height: 218px;
$list-desktop-on-image: '../img/sprites/sprite.png';
$list-desktop-on: (199px, 71px, -199px, -71px, 17px, 17px, 228px, 218px, '../img/sprites/sprite.png', 'list-desktop-on', );
$list-desktop-name: 'list-desktop';
$list-desktop-x: 199px;
$list-desktop-y: 20px;
$list-desktop-offset-x: -199px;
$list-desktop-offset-y: -20px;
$list-desktop-width: 17px;
$list-desktop-height: 17px;
$list-desktop-total-width: 228px;
$list-desktop-total-height: 218px;
$list-desktop-image: '../img/sprites/sprite.png';
$list-desktop: (199px, 20px, -199px, -20px, 17px, 17px, 228px, 218px, '../img/sprites/sprite.png', 'list-desktop', );
$list-mobile-on-name: 'list-mobile-on';
$list-mobile-on-x: 72px;
$list-mobile-on-y: 130px;
$list-mobile-on-offset-x: -72px;
$list-mobile-on-offset-y: -130px;
$list-mobile-on-width: 32px;
$list-mobile-on-height: 32px;
$list-mobile-on-total-width: 228px;
$list-mobile-on-total-height: 218px;
$list-mobile-on-image: '../img/sprites/sprite.png';
$list-mobile-on: (72px, 130px, -72px, -130px, 32px, 32px, 228px, 218px, '../img/sprites/sprite.png', 'list-mobile-on', );
$list-mobile-name: 'list-mobile';
$list-mobile-x: 136px;
$list-mobile-y: 130px;
$list-mobile-offset-x: -136px;
$list-mobile-offset-y: -130px;
$list-mobile-width: 32px;
$list-mobile-height: 32px;
$list-mobile-total-width: 228px;
$list-mobile-total-height: 218px;
$list-mobile-image: '../img/sprites/sprite.png';
$list-mobile: (136px, 130px, -136px, -130px, 32px, 32px, 228px, 218px, '../img/sprites/sprite.png', 'list-mobile', );
$list-view-icon-on-name: 'list-view-icon-on';
$list-view-icon-on-x: 130px;
$list-view-icon-on-y: 80px;
$list-view-icon-on-offset-x: -130px;
$list-view-icon-on-offset-y: -80px;
$list-view-icon-on-width: 40px;
$list-view-icon-on-height: 40px;
$list-view-icon-on-total-width: 228px;
$list-view-icon-on-total-height: 218px;
$list-view-icon-on-image: '../img/sprites/sprite.png';
$list-view-icon-on: (130px, 80px, -130px, -80px, 40px, 40px, 228px, 218px, '../img/sprites/sprite.png', 'list-view-icon-on', );
$list-view-icon-name: 'list-view-icon';
$list-view-icon-x: 0px;
$list-view-icon-y: 130px;
$list-view-icon-offset-x: 0px;
$list-view-icon-offset-y: -130px;
$list-view-icon-width: 40px;
$list-view-icon-height: 40px;
$list-view-icon-total-width: 228px;
$list-view-icon-total-height: 218px;
$list-view-icon-image: '../img/sprites/sprite.png';
$list-view-icon: (0px, 130px, 0px, -130px, 40px, 40px, 228px, 218px, '../img/sprites/sprite.png', 'list-view-icon', );
$magnifier-name: 'magnifier';
$magnifier-x: 138px;
$magnifier-y: 170px;
$magnifier-offset-x: -138px;
$magnifier-offset-y: -170px;
$magnifier-width: 22px;
$magnifier-height: 22px;
$magnifier-total-width: 228px;
$magnifier-total-height: 218px;
$magnifier-image: '../img/sprites/sprite.png';
$magnifier: (138px, 170px, -138px, -170px, 22px, 22px, 228px, 218px, '../img/sprites/sprite.png', 'magnifier', );
$mail-icon-name: 'mail-icon';
$mail-icon-x: 0px;
$mail-icon-y: 193px;
$mail-icon-offset-x: 0px;
$mail-icon-offset-y: -193px;
$mail-icon-width: 24px;
$mail-icon-height: 12px;
$mail-icon-total-width: 228px;
$mail-icon-total-height: 218px;
$mail-icon-image: '../img/sprites/sprite.png';
$mail-icon: (0px, 193px, 0px, -193px, 24px, 12px, 228px, 218px, '../img/sprites/sprite.png', 'mail-icon', );
$nav-icon-close-mobile-name: 'nav-icon-close-mobile';
$nav-icon-close-mobile-x: 170px;
$nav-icon-close-mobile-y: 49px;
$nav-icon-close-mobile-offset-x: -170px;
$nav-icon-close-mobile-offset-y: -49px;
$nav-icon-close-mobile-width: 27px;
$nav-icon-close-mobile-height: 22px;
$nav-icon-close-mobile-total-width: 228px;
$nav-icon-close-mobile-total-height: 218px;
$nav-icon-close-mobile-image: '../img/sprites/sprite.png';
$nav-icon-close-mobile: (170px, 49px, -170px, -49px, 27px, 22px, 228px, 218px, '../img/sprites/sprite.png', 'nav-icon-close-mobile', );
$nav-icon-mobile-name: 'nav-icon-mobile';
$nav-icon-mobile-x: 160px;
$nav-icon-mobile-y: 170px;
$nav-icon-mobile-offset-x: -160px;
$nav-icon-mobile-offset-y: -170px;
$nav-icon-mobile-width: 20px;
$nav-icon-mobile-height: 20px;
$nav-icon-mobile-total-width: 228px;
$nav-icon-mobile-total-height: 218px;
$nav-icon-mobile-image: '../img/sprites/sprite.png';
$nav-icon-mobile: (160px, 170px, -160px, -170px, 20px, 20px, 228px, 218px, '../img/sprites/sprite.png', 'nav-icon-mobile', );
$plus-icon-name: 'plus_icon';
$plus-icon-x: 115px;
$plus-icon-y: 94px;
$plus-icon-offset-x: -115px;
$plus-icon-offset-y: -94px;
$plus-icon-width: 14px;
$plus-icon-height: 14px;
$plus-icon-total-width: 228px;
$plus-icon-total-height: 218px;
$plus-icon-image: '../img/sprites/sprite.png';
$plus-icon: (115px, 94px, -115px, -94px, 14px, 14px, 228px, 218px, '../img/sprites/sprite.png', 'plus_icon', );
$popup-close-name: 'popup-close';
$popup-close-x: 65px;
$popup-close-y: 0px;
$popup-close-offset-x: -65px;
$popup-close-offset-y: 0px;
$popup-close-width: 65px;
$popup-close-height: 65px;
$popup-close-total-width: 228px;
$popup-close-total-height: 218px;
$popup-close-image: '../img/sprites/sprite.png';
$popup-close: (65px, 0px, -65px, 0px, 65px, 65px, 228px, 218px, '../img/sprites/sprite.png', 'popup-close', );
$previous-name: 'previous';
$previous-x: 199px;
$previous-y: 125px;
$previous-offset-x: -199px;
$previous-offset-y: -125px;
$previous-width: 13px;
$previous-height: 20px;
$previous-total-width: 228px;
$previous-total-height: 218px;
$previous-image: '../img/sprites/sprite.png';
$previous: (199px, 125px, -199px, -125px, 13px, 20px, 228px, 218px, '../img/sprites/sprite.png', 'previous', );
$print-icon-name: 'print-icon';
$print-icon-x: 199px;
$print-icon-y: 54px;
$print-icon-offset-x: -199px;
$print-icon-offset-y: -54px;
$print-icon-width: 17px;
$print-icon-height: 17px;
$print-icon-total-width: 228px;
$print-icon-total-height: 218px;
$print-icon-image: '../img/sprites/sprite.png';
$print-icon: (199px, 54px, -199px, -54px, 17px, 17px, 228px, 218px, '../img/sprites/sprite.png', 'print-icon', );
$print-name: 'print';
$print-x: 88px;
$print-y: 115px;
$print-offset-x: -88px;
$print-offset-y: -115px;
$print-width: 21px;
$print-height: 15px;
$print-total-width: 228px;
$print-total-height: 218px;
$print-image: '../img/sprites/sprite.png';
$print: (88px, 115px, -88px, -115px, 21px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'print', );
$search-box-mobile-name: 'search-box-mobile';
$search-box-mobile-x: 115px;
$search-box-mobile-y: 65px;
$search-box-mobile-offset-x: -115px;
$search-box-mobile-offset-y: -65px;
$search-box-mobile-width: 15px;
$search-box-mobile-height: 15px;
$search-box-mobile-total-width: 228px;
$search-box-mobile-total-height: 218px;
$search-box-mobile-image: '../img/sprites/sprite.png';
$search-box-mobile: (115px, 65px, -115px, -65px, 15px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'search-box-mobile', );
$search-box-name: 'search-box';
$search-box-x: 115px;
$search-box-y: 80px;
$search-box-offset-x: -115px;
$search-box-offset-y: -80px;
$search-box-width: 14px;
$search-box-height: 14px;
$search-box-total-width: 228px;
$search-box-total-height: 218px;
$search-box-image: '../img/sprites/sprite.png';
$search-box: (115px, 80px, -115px, -80px, 14px, 14px, 228px, 218px, '../img/sprites/sprite.png', 'search-box', );
$search-icon-mobile-name: 'search-icon-mobile';
$search-icon-mobile-x: 199px;
$search-icon-mobile-y: 0px;
$search-icon-mobile-offset-x: -199px;
$search-icon-mobile-offset-y: 0px;
$search-icon-mobile-width: 20px;
$search-icon-mobile-height: 20px;
$search-icon-mobile-total-width: 228px;
$search-icon-mobile-total-height: 218px;
$search-icon-mobile-image: '../img/sprites/sprite.png';
$search-icon-mobile: (199px, 0px, -199px, 0px, 20px, 20px, 228px, 218px, '../img/sprites/sprite.png', 'search-icon-mobile', );
$share-email-name: 'share-email';
$share-email-x: 199px;
$share-email-y: 161px;
$share-email-offset-x: -199px;
$share-email-offset-y: -161px;
$share-email-width: 17px;
$share-email-height: 15px;
$share-email-total-width: 228px;
$share-email-total-height: 218px;
$share-email-image: '../img/sprites/sprite.png';
$share-email: (199px, 161px, -199px, -161px, 17px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'share-email', );
$share-facebook-name: 'share-facebook';
$share-facebook-x: 180px;
$share-facebook-y: 170px;
$share-facebook-offset-x: -180px;
$share-facebook-offset-y: -170px;
$share-facebook-width: 16px;
$share-facebook-height: 15px;
$share-facebook-total-width: 228px;
$share-facebook-total-height: 218px;
$share-facebook-image: '../img/sprites/sprite.png';
$share-facebook: (180px, 170px, -180px, -170px, 16px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'share-facebook', );
$share-pinterest-name: 'share-pinterest';
$share-pinterest-x: 199px;
$share-pinterest-y: 176px;
$share-pinterest-offset-x: -199px;
$share-pinterest-offset-y: -176px;
$share-pinterest-width: 16px;
$share-pinterest-height: 15px;
$share-pinterest-total-width: 228px;
$share-pinterest-total-height: 218px;
$share-pinterest-image: '../img/sprites/sprite.png';
$share-pinterest: (199px, 176px, -199px, -176px, 16px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'share-pinterest', );
$share-twitter-name: 'share-twitter';
$share-twitter-x: 109px;
$share-twitter-y: 115px;
$share-twitter-offset-x: -109px;
$share-twitter-offset-y: -115px;
$share-twitter-width: 16px;
$share-twitter-height: 15px;
$share-twitter-total-width: 228px;
$share-twitter-total-height: 218px;
$share-twitter-image: '../img/sprites/sprite.png';
$share-twitter: (109px, 115px, -109px, -115px, 16px, 15px, 228px, 218px, '../img/sprites/sprite.png', 'share-twitter', );
$slideindicators-active-name: 'slideindicators-active';
$slideindicators-active-x: 219px;
$slideindicators-active-y: 30px;
$slideindicators-active-offset-x: -219px;
$slideindicators-active-offset-y: -30px;
$slideindicators-active-width: 9px;
$slideindicators-active-height: 9px;
$slideindicators-active-total-width: 228px;
$slideindicators-active-total-height: 218px;
$slideindicators-active-image: '../img/sprites/sprite.png';
$slideindicators-active: (219px, 30px, -219px, -30px, 9px, 9px, 228px, 218px, '../img/sprites/sprite.png', 'slideindicators-active', );
$slideindicators-inactive-name: 'slideindicators-inactive';
$slideindicators-inactive-x: 219px;
$slideindicators-inactive-y: 39px;
$slideindicators-inactive-offset-x: -219px;
$slideindicators-inactive-offset-y: -39px;
$slideindicators-inactive-width: 9px;
$slideindicators-inactive-height: 9px;
$slideindicators-inactive-total-width: 228px;
$slideindicators-inactive-total-height: 218px;
$slideindicators-inactive-image: '../img/sprites/sprite.png';
$slideindicators-inactive: (219px, 39px, -219px, -39px, 9px, 9px, 228px, 218px, '../img/sprites/sprite.png', 'slideindicators-inactive', );
$tooltip-name: 'tooltip';
$tooltip-x: 199px;
$tooltip-y: 145px;
$tooltip-offset-x: -199px;
$tooltip-offset-y: -145px;
$tooltip-width: 16px;
$tooltip-height: 16px;
$tooltip-total-width: 228px;
$tooltip-total-height: 218px;
$tooltip-image: '../img/sprites/sprite.png';
$tooltip: (199px, 145px, -199px, -145px, 16px, 16px, 228px, 218px, '../img/sprites/sprite.png', 'tooltip', );
$whats-this-name: 'whats-this';
$whats-this-x: 24px;
$whats-this-y: 193px;
$whats-this-offset-x: -24px;
$whats-this-offset-y: -193px;
$whats-this-width: 12px;
$whats-this-height: 12px;
$whats-this-total-width: 228px;
$whats-this-total-height: 218px;
$whats-this-image: '../img/sprites/sprite.png';
$whats-this: (24px, 193px, -24px, -193px, 12px, 12px, 228px, 218px, '../img/sprites/sprite.png', 'whats-this', );
$whatsthis-name: 'whatsthis';
$whatsthis-x: 36px;
$whatsthis-y: 193px;
$whatsthis-offset-x: -36px;
$whatsthis-offset-y: -193px;
$whatsthis-width: 12px;
$whatsthis-height: 12px;
$whatsthis-total-width: 228px;
$whatsthis-total-height: 218px;
$whatsthis-image: '../img/sprites/sprite.png';
$whatsthis: (36px, 193px, -36px, -193px, 12px, 12px, 228px, 218px, '../img/sprites/sprite.png', 'whatsthis', );
$spritesheet-width: 228px;
$spritesheet-height: 218px;
$spritesheet-image: '../img/sprites/sprite.png';
$spritesheet-sprites: ($-article-listing-email, $-email-button, $-next, $-video-thumbnail, $ad-choice-icon, $arrow-down, $arrow-left, $arrow-right, $arrow-up, $arrowbox-next, $arrowbox-prev, $compare-down-on, $compare-down, $compareclose, $dropdown-arrow, $error, $footer-social-fb-m, $footer-social-fb, $footer-social-instagram-m, $footer-social-instagram, $footer-social-pinterest-m, $footer-social-pinterest, $footer-social-twitter-m, $footer-social-twitter, $footer-social-yt-m, $footer-social-yt, $grid-desktop-on, $grid-desktop, $grid-mobile-on, $grid-mobile, $grid-view-icon-on, $grid-view-icon, $icon-facet-on, $icon-facet, $list-desktop-on, $list-desktop, $list-mobile-on, $list-mobile, $list-view-icon-on, $list-view-icon, $magnifier, $mail-icon, $nav-icon-close-mobile, $nav-icon-mobile, $plus-icon, $popup-close, $previous, $print-icon, $print, $search-box-mobile, $search-box, $search-icon-mobile, $share-email, $share-facebook, $share-pinterest, $share-twitter, $slideindicators-active, $slideindicators-inactive, $tooltip, $whats-this, $whatsthis, );
$spritesheet: (228px, 218px, '../img/sprites/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
