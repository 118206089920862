﻿/*	=PRODUCT FINDER PAGE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/

.pageBanner {
    margin: 0 0 40px 0;
    @include mobile {
        width: 100%;
        text-align: center;
        margin: 0;
    }

    .banner {
        position: relative;
        width: 100%;
        height: 360px;

        @include mobile {
            width: 100%;
            height: inherit;

            img {max-width: 100%;}
        }

        .button {
            position: absolute;
            span {
                color: #bbb;
                padding: 0 30px;
                @include mobile {padding: 0 10px;}
            }
        }
    }
}
.headline__desktop {
    @include desktop {display: block;}
    @include mobile {display: none;}
}
.headline__mobile {
    @include desktop {display: none;}
    @include mobile {
    width: 100%;
    @include clearfix;
        .nav li {
            float: left;

            .popupButton {
                display: inline-block;
                border: 1px solid #444;
                padding: 7.8px 15px;
                font-size: 1.1rem;
                color: #bbb;
            }
            &.popupTrigger {
                float: left;
                margin-right: 2%;
            }
            &.view {
                float: right;
            }
        }
    }
}

.headline {
    border-top: 1px dashed #bbb;
    border-bottom: 1px dashed #bbb;
    height: 74px;
    line-height: 74px;
    color: #b9b9b9;
    vertical-align: middle;
    font-size: 1.2rem;
    @include clearfix;
    @include mobile {
        border: 0;
        height: auto;
        line-height: normal;
        margin-bottom: 40px;
    }

    .count {
        float: left;
        vertical-align: middle;
        @include mobile {
            display: block;
            float: none;
            margin-bottom: 25px;
            .mobileOnly {display: inline-block;padding-right: 3px;}
        }
    }
    .viewType {
        float: right;
    }

    .view1 {
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        @include hideText;

        @include desktop { @include sprite($grid-desktop) }
        @include mobile  { @include sprite($grid-mobile) }

        &.active, &:hover {
            @include desktop { @include sprite($grid-desktop-on); }
            @include mobile { @include sprite($grid-mobile-on); }
        }
    }
    .view2 {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        cursor: pointer;
        @include hideText;

        @include desktop { @include sprite($list-desktop) }
        @include mobile  { @include sprite($list-mobile) }

        &.active, &:hover {
            @include desktop { @include sprite($list-desktop-on); }
            @include mobile { @include sprite($list-mobile-on); }
        }
    }
    .sortSelect {
        margin-left: 20px;
        margin-top: 20px;
        width: 200px;
        float: right;
        @include mobile {display: none;}
    }
    .popupButtons {
        @include mobile {
            float: left;
            .filterButton {
                height: 32px;
                width: 60px;
                color: #bbb;
                text-align: center;
                padding: 5px 0;
                font-weight: 400;
                margin-right: 20px;
                font-size: 1.1rem;
                line-height: 20px;
            }
            .sortButton {
                min-width: 155px;
                min-height: 32px;
                line-height: 20px;
                color: #bbb;
                padding: 5px 10px;
                text-align: center;
                font-size: 1.1rem;
            }
        }
    }
}

.sortSelect {
    height: 32px;
    border: 1px solid #666;
    position: relative;
    vertical-align: middle;
    z-index: 2;
    &:after {
        content: "";
        @include sprite($dropdown-arrow);
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
    }

    select {
        background: none;
        display: inline-block;
        font-family: 'GothamLight';
        font-size: 1.2rem;
        border: 0;
        color: #bbb;
        width: inherit;
        height: inherit;
        float: left;
        //line-height: 32px;
        padding-left: 5px;
        -moz-appearance: none;
        -webkit-appearance: none;
        -ms-appearance: none; appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
}

.selectedTags {
    color: #bbb;
    padding-bottom: 15px;
    font-size: 1.25rem;

    a {
        color: #bbb;
        cursor: pointer;
    }
    ul li {
        display: inline-block;
        margin-right: 5px;
        a.filterName {
            display: inline-block;
            background: #000;
            padding: 5px;
            position: relative;
            &:after {
                content: "";
                width: 10px;
                height: 10px;
                display: inline-block;
                vertical-align: middle;
                margin-left: 15px;
                background: url("../img/Overlay_Close_Cross.png") no-repeat right center;
            }
        }
    }
}

.bannerBottom {
    position: relative;
    width: 100%;
    margin: 20px auto;
    img {
        max-width: 100%;
    }
    .button {
        position: absolute;
    }
}
.main__header {
    .page__title {
        text-transform: uppercase;
        color: #fff;
        font-size: 3.4rem;
        font-weight: 300;
        margin-bottom: 30px;
        line-height: 34px;
        @include mobile {
            font-size: 1.8rem;
            line-height: 20px;
        }
    }
}
.main__content {
    margin: 20px 0;
    position: relative;

    &.gridHidden {
        @include desktop {width: 720px;overflow: hidden;}
    }
    @include clearfix;
    @include mobile {
        width: 100%;
    }
}

// masonry wrapper size
#grid {
    @include desktop {width: 740px;}
    @include mobile {
        height: auto !important;
    }
}


/*  =PRODUCT TILE
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.product__tile {
   width: 225px;
   height: 538px;
   padding: 20px;
   float: left;
   background: #0d0d0d;
   position: relative;
   //z-index: 8000;
   margin-right: 22px;
   margin-bottom: 22px;
   @include mobile {width: 49.5%; height: auto; padding: 5px; margin-right: 0; margin-bottom: 1%;}

    &:nth-child(3n+3) {
        @include desktop {margin-right: 0;}
    }
    &:nth-child(2n+1) {
        @include mobile {margin-right: 1%;}
    }
    .product__shop {
        color: #bbb;
        @include mobile {
            width: 100%;
            float: left;
            margin: 0;
            position: relative;
            height: 260px;
        }

        .contentWrap {
            position: absolute;
            bottom: 10px;
            width: 185px;
            @include mobile {
                width: 100%;
                height: inherit;
                position: static;
            }
        }
        .product__description {
            display: none;
        }
        .product__compare {
            border-top: 1px dashed #666;
            width: 100%;
            vertical-align: bottom;
            text-align: center;
            padding: 15px 0 5px 0;
            position: relative;
            margin-top: 20px;
            //z-index: 9000;

            @include mobile {
                border: 0;
                position: absolute;
                bottom: 10px;
            }
            &.active {
                .compareLink {
                    color: #fff !important;
                    cursor: pointer !important;
                    &:before {
                        background: #fff;
                    }
                }
            }
            &.disabled {
              // cursor: not-allowed;
                .compareLink {
                  cursor: not-allowed;
                  color: #333;
                }
            }
            .compareLink {
                color: #666;
                font-size: 1.2rem;
                cursor: pointer;

                @include mobile {
                    color: #bbb;
                    padding: 8px 25px;
                    font-size: 1.1rem;
                    //border: 1px solid #666;
                    border: 0;
                    width: 100%;
                    display: block;
                    background: #1a1a1a;
                }

                &:before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    margin-right: 5px;
                    border: 1px solid #666;
                    @include mobile {display: none;}
                }
            }
        }
    }
    .product__packshot {
        margin-bottom: 40px;
        position: relative;
        width: 180px;
        height: 228px;
        text-align: center;
        cursor: pointer;
        @include desktop {
            max-width: 100%;
        }
        @include mobile {
            width: 140px;
            height: 180px;
            margin: 0 auto 30px auto;
            img {
                max-width: 100%;
            }
        }

        .product__quickView {display: none;}

        &:hover {
            .product__quickView {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 90px;
                height: 90px;
                margin-left: -45px;
                margin-top: -45px;
                background: #b30022;
                opacity: .5;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.4rem;
                padding: 25px 5px 0;
                @include mobile {display: none;}
            }
        }
    }
    .product__title {
        font-size: 1.6rem;
        text-align: left;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 300;

        @include mobile {
            color: #bbb;
            margin-bottom: 10px;
        }
    }
}

.main__content.viewHorizontal {
    .product__tile {
        width: 100%;
        height: auto;
        margin-right: 0;
        padding-bottom: 10px;
        @include mobile {
            width: 100%;
            height: 300px;
            margin-right: 0;
            padding-top: 2%;
            margin-bottom: 20px;
        }

        .contentWrap {
            width: auto;
        }
        .product__title {
            margin: 10px 0 15px 0;
            @include mobile {
                font-size: 2rem;
                color: #bbb;
            }
        }
        .product__packshot {
            float: left;
            margin-bottom: 0;
            @include mobile {
                width: 45%;
                height: auto;
                float: left;
                img {max-width: 100%;}
            }
        }
        .product__shop {
            float: left;
            width: 445px;
            position: relative;
            margin-left: 40px;
            .price {
                padding: 0 0 10px 0;
            }
            @include mobile {
                width: 55%;
                float: left;
                margin: 0;
                position: relative;
                height: 260px;
            }
        }
        .product__description {
            display: inline-block;
            font-size: 1.2rem;
            height: 90px;
            margin-bottom: 10px;
            overflow: hidden;
            @include mobile {
                display: none;
            }
        }
        .rating {
            visibility: visible;
            &.empty {
                visibility: hidden;
            }
        }
        .contentWrap {
            position: static;
            width: inherit;
            @include mobile {
                width: 100%;
            }
        }
        .product__compare {
            @include mobile {
                position:absolute;
                bottom: 0;
                border: 0;
                text-align: left;

                a.compareLink {
                    color: #bbb;
                    padding: 3px 25px;
                    font-size: 1.1rem;
                    border: 1px solid #666;
                    background: none;
                    width: auto;
                    display: inline-block;
                    cursor: pointer;

                    &:before {display: none;}
                }
            }
        }
    }
}

.product__rating {
    @include clearfix;
    line-height: 21px;
    cursor: pointer;

    .ratingWrap {
        height: 21px;
        width: 100px;
        float: left;
        position: relative;
        .ratingRed {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            width: 90%;
        }
    }
    .ratingCount {
        font-size: 1.2rem;
        padding-left: 8px;
        float: left;
    }
}

.product__price {
    font-size: 1.5rem;
    padding: 5px 0 20px 0;
    display: inline-block;
    visibility: visible;
    small {
        font-size: 1.1rem;
        padding-left: 3px;
    }
    &.empty {
        visibility: hidden;
    }
}



/*  =FILTER PANEL
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -   */

.filterPanel {
    &.desktopPanel {
        @include mobile {
            display: none;
        }
    }
    border-top: 1px dashed #666;
    padding-bottom: 25px;
    padding-top: 18px;
    color: #bbb;
    position: relative;
    @include mobile {
        border: 0;
        padding-top: 0;
        padding-bottom: 10px;
    }

    &:last-child {
        border-bottom: 1px dashed #666;
        @include mobile {border:0;}
    }
    .filter__title {
        font-size: 1.2rem;
        cursor: pointer;
        position: relative;
        padding-left: 15px;
        @include mobile {color: #fff;text-transform: uppercase;padding:0;}

        &:before {
            content: "";
            display: inline-block;
            margin-right: 9px;
            vertical-align: middle;
            position: absolute;
            left: 0;
            top: 4px;
            @include sprite($icon-facet-on);
            @include mobile {display: none;}
        }

        &.active:before {
            @include sprite($icon-facet);
        }
    }
    .filter__scroll {
        max-height: 220px;
        width: 210px;
        margin-top: 8px;
        overflow: hidden;
        position: relative;
        transition: height 0.7s;
        &.hidden {
            height: 0px;
        }
        @include mobile {
            width: 100%;
            max-height: 100%;
            overflow: visible;
        }
        &.deactivate {
            .iScrollLoneScrollbar {
                display: none;
            }
        }
    }
    .filter__item {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 18px;
        cursor: pointer;
        margin-bottom: 3px;
        @include clearfix;
        @include mobile {
            margin-bottom: 5px;
            line-height: 30px;
            font-size: 1.1rem;
            padding-left: 10px;
            border: 1px solid #666;

            &.active {
                background-color: #708090;
            }
        }

        .check {
            width: 10px;
            height: 10px;
            display: inline-block;
            border: 1px solid #666;
            margin-right: 7px;
            margin-top: 4px;
            float: left;
            @include mobile {display: none;}
        }
        .count {
            margin-left: 10px;
            font-size: 1.2rem;
            @include mobile {margin-left:3px;}
        }

        &.active .check {
            background: #bbb;
            border: 1px solid #bbb;
        }

        .filter__info {
            float: left;
            width: 170px;
            @include mobile {width: 100%;}
        }
    }
}

.iScrollLoneScrollbar {
    background: #949599;
    border: 0;
    width: 10px !important;

    .iScrollIndicator {
        width: 10px !important;
        background: #b30022 !important;
        border: 0 !important;
        border-radius: 0 !important;
    }
}

.collectionProductGroup {
    clear: both;
    margin-bottom: 50px;

    .collectionProductGroup__inner {
        clear: both;
        overflow: hidden;
    }
    .collectionProductGroup__buttons {
        width: 100%;
        display: block;
        text-align: center;
        .button {
            background-color: transparent;
            border: 1px solid #bbb;
            color: #bbb;
            text-align: left;
            padding: 14px 45px 0 10px;
            height: 45px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                right: 10px;
                @include sprite($plus-icon);
            }
        }
    }
    h1.collectionProduct__title {
        font-size: 3.4rem;
        font-weight: 300;
        text-transform: uppercase;
        margin: 15px 0 30px 0;
    }
}
