﻿/*	=FONT VARIABLES
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/
$font-bold: "ClanNarrow-Bold";
$font-book: "ClanNarrow-Book";
$font-medium: "ClanNarrow-Medium";
$font-news: "ClanNarrow-News";
$font-thin: "ClanNarrow-Thin";


/*	=GLOBAL VARIABLES
- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -	*/
$color-bg: #fff;
$color-content: #000;
$color-golden-link: #a98659;
